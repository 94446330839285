import React from "react";
import {
    withRouter
} from "react-router-dom";
import {
    ProductCollectionBar,
    ProductCollectionCarousel
} from "@shopcrate/shopcrate-framework";

import Helmet from "../components/Helmet";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>ShopCrate Demo Shop</h1>
                </div>
            </div>
            <div className="container">
                <ProductCollectionBar collection={ "cartrackers" }/>
                <ProductCollectionBar collection={ "second-collection" }/>
                <ProductCollectionBar collection={ "rentals" }/>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "cartrackers" }/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "second-collection" }/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "single-product" }/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ProductCollectionCarousel collection={ "rentals" }/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(React.memo(Home));
