import React, {
    createContext
} from "react";
import {
    withRouter
} from "react-router-dom";
import i18next from "i18next";

import shopcrateApi from "../ShopCrateAPI";

export const ProductContext = createContext(null);

class ProductManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                error: null,
                errorCode: null,
                releaseDate: null,
                product: null,

                getFormattedPrice: this.getFormattedPrice.bind(this)
            }
        }
    }

    setContextState(state) {
        this.setState(previousState => {
            return { context: { ...previousState.context, ...state } }
        });
    }

    componentDidMount() {
        if(this.props.productId) {
            this.getProduct(this.props.productId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.productId && this.props.productId !== prevProps.productId) {
            this.getProduct(this.props.productId);
        }
    }

    getProduct(productId) {
        this.setContextState({ product: null, error: null });
        shopcrateApi.post("/getProduct", { productId })
            .then((response) => {
                if(response.data.valid) {
                    const product = response.data.product;
                    this.setContextState({ product });
                    if(!this.props.productId && product.url !== this.props.match.params.productUrl) {
                        this.props.history.replace("/product/" + this.state.context.product.id + "/" + this.state.context.product.url);
                    }
                } else {
                    this.setContextState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")", errorCode: response.data.error });
                    if(response.data.error === "NOT_RELEASED_YET") {
                        this.setContextState({ releaseDate: response.data.date });
                    }
                }
            })
            .catch(() => {
                this.setContextState({ error: i18next.t("errorGeneral") });
            })
    }

    getFormattedPrice() {
        if(this.state.context.product == null) {
            return null;
        }
        let price = Math.floor(this.state.context.product.price);
        let cents = Math.round((this.state.context.product.price - price) * 100);
        if(cents > 0 && cents < 10) {
            return {
                price: price,
                cents: `0${cents}`
            }
        } else if(cents !== 0) {
            return {
                price: price,
                cents: cents
            }
        } else {
            return {
                price: price,
                cents: "-"
            }
        }
    }

    render() {
        return (
            <ProductContext.Provider value={ this.state.context }>
                { this.props.children }
            </ProductContext.Provider>
        )
    }
}

export default withRouter(ProductManager);
