import React from "react";

function ProductPageSpecifications() {
    return (
        <table className="table table-striped">
            <tbody>
            <tr>
                <th scope="row">Artikelnummer</th>
                <td>1234567</td>
            </tr>
            <tr>
                <th scope="row">Merk</th>
                <td>ShopCrate</td>
            </tr>
            <tr>
                <th scope="row">Garantie</th>
                <td>3 jaar</td>
            </tr>
            </tbody>
        </table>
    )
}

export default React.memo(ProductPageSpecifications);
