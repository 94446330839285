import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductPagePriceDisplay({ showCurrencySymbol = true }) {
    const productContext = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!productContext.product || !shopContext.shop) {
        return (
            <h1 className="mt-0">
                <Skeleton/>
            </h1>
        )
    }
    if(!shopContext.shop.showPricing) {
        return null;
    }
    const price = productContext.getFormattedPrice();
    const discountPrice = productContext.getFormattedDiscountPrice();
    const currentPrice = discountPrice ? discountPrice : price;
    return (
        <div className="product-page-price-container">
            { discountPrice && (
                <div className="product-page-original-price">
                    { price.price },{ price.cents }
                </div>
            )}
            <div className="product-page-price">
                { showCurrencySymbol && (
                    <span className="product-page-price-symbol">
                        &euro;{" "}
                    </span>
                ) }
                { currentPrice.price },
                <span className="product-page-price-cents">
                    { currentPrice.cents }
                </span>
            </div>
        </div>
    )
}

export default React.memo(ProductPagePriceDisplay);
