import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";

import ShopContext from "../../../../context/internal/ShopManager";
import CourseSidebar from "./components/CourseSidebar";
import CourseLessonContent from "./components/CourseLessonContent";
import useHideBodyScroll from "../../../../hooks/useHideBodyScroll";
import CourseLessonFooter from "./components/CourseLessonFooter";
import useWindowSize from "../../../../hooks/WindowSize";
import CourseLoadingState from "./components/CourseLoadingState";
import CourseErrorState from "./components/CourseErrorState";

function AccountCourseDetail() {
    const shopContext = useContext(ShopContext);
    const match = useRouteMatch();
    const windowSize = useWindowSize();
    const courseId = useMemo(() => {
        return parseInt(match.params.courseId);
    }, [match.params.courseId]);
    const courseToken = useMemo(() => {
        return match.params.courseToken;
    }, [match.params.courseToken]);
    const lessonId = useMemo(() => {
        return parseInt(match.params.lessonId);
    }, [match.params.lessonId]);
    const baseUrl = useMemo(() => {
        if(courseId) {
            return `/account/course/${ courseId }`;
        } else if(courseToken) {
            return `/course-demo/${ courseToken }`;
        }
    }, [courseId, courseToken]);

    const [course, setCourse] = useState(null);
    const [currentChapter, setCurrentChapter] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [error, setError] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [completionSaving, setCompletionSaving] = useState(false);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);

    const setBodyScrollDisabled = useHideBodyScroll();

    const headerRef = useCallback((node) => {
        // Update headerHeight when windowSize changes
        if(node !== null) {
            setHeaderHeight(node.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);

    const mobile = useMemo(() => {
        return windowSize.width <= 950;
    }, [windowSize.width]);

    useEffect(() => {
        setBodyScrollDisabled(mobile && mobileSidebarOpen);
    }, [setBodyScrollDisabled, mobile, mobileSidebarOpen]);

    useEffect(() => {
        if(!headerRef.current) {
            return;
        }
        setHeaderHeight(headerRef.current.clientHeight);
    }, [headerRef, windowSize]);

    const refreshCourse = useMemo(() => {
        return async () => {
            if(!courseId && !courseToken) {
                setError(i18next.t("errorCourseNotFound"));
                return;
            }
            setCourse(null);
            setError(null);
            const data = courseId ? {
                courseId
            } : {
                courseToken
            }
            const url = courseId ? "/getCourse" : "/getCourseWithToken";
            try {
                const response = await shopContext.shopApi.post(url, data);
                if(response.data.valid) {
                    setCourse(response.data.course);
                } else {
                    if(response.data.error) {
                        const errorCode = response.data.error;
                        switch(errorCode) {
                            case "NO_ACCESS":
                                setError(i18next.t("errorCourseAccessExpired"));
                                break;
                            default:
                                setError(i18next.t("errorGeneral") + ` (${errorCode})`);
                                break;
                        }
                    } else {
                        setError(i18next.t("errorGeneral"));
                    }
                }
            } catch(error) {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            }
        }
    }, [shopContext.shopApi, courseId, courseToken]);

    useEffect(() => {
        refreshCourse();
    }, [refreshCourse]);

    useEffect(() => {
        if(!course || !lessonId) {
            setCurrentChapter(null);
            setCurrentLesson(null);
            return;
        }
        let foundChapter = null;
        let foundLesson = null;
        for(const chapter of course.chapters) {
            for(const lesson of chapter.lessons) {
                if(lesson.id === lessonId) {
                    foundLesson = lesson;
                    break;
                }
            }
            if(foundLesson) {
                foundChapter = chapter;
                break;
            }
        }
        setCurrentChapter(foundChapter);
        setCurrentLesson(foundLesson)
    }, [lessonId, course]);

    const updateLessonUserInfo = useCallback(async (completed = undefined) => {
        if(!currentLesson || !course) {
            return;
        }
        if(course.demoMode) {
            return;
        }
        try {
            if(completed !== undefined) {
                setCompletionSaving(true);
                setUpdateError(null);
            }
            const response = await shopContext.shopApi.post("/updateCourseLessonUserInfo", {
                courseId: course.id,
                courseLessonId: currentLesson.id,
                completed: completed === undefined ? undefined : (completed ? 1 : 0)
            });
            if(response.data.course) {
                setCourse(response.data.course);
            }
        } catch(error) {
            console.error(error);
            if(completed !== undefined) {
                setUpdateError(i18next.t("errorGeneral"));
            }
        } finally {
            if(completed !== undefined) {
                setCompletionSaving(false);
            }
        }
    }, [shopContext.shopApi, course, currentLesson]);

    useEffect(() => {
        if(!updateLessonUserInfo) {
            return;
        }
        updateLessonUserInfo();
        const timer = setInterval(() => {
            updateLessonUserInfo();
        }, 60 * 1000);
        return () => clearInterval(timer);
    }, [updateLessonUserInfo]);

    const className = useMemo(() => {
        let classNameList = ["shopcrate-course-fullscreen"];
        if(mobile) {
            classNameList.push("mobile");
            if(mobileSidebarOpen) {
                classNameList.push("sidebar-open");
            }
        }
        return classNameList.join(" ");
    }, [mobile, mobileSidebarOpen]);

    if(error) {
        return (
            <CourseErrorState error={ error }/>
        );
    }
    if(!course) {
        return (
            <CourseLoadingState/>
        )
    }
    return (
        <div className={ className }>
            { mobile && (
                <div
                    className="course-mobile-content-blur"
                    onClick={ () => setMobileSidebarOpen(false) }
                />
            )}
            <div className="course-header" ref={ headerRef }>
                { mobile && (
                    <div className="mr-2">
                        <Button
                            variant="light"
                            onClick={ () => setMobileSidebarOpen(true) }
                        >
                            <i className="fas fa-bars fa-fw"/>
                        </Button>
                    </div>
                )}
                <div className="course-title">
                    { course.title }
                    { course.demoMode && (
                        <span className="badge badge-pill badge-primary ml-2">
                            Demo
                        </span>
                    )}
                </div>
                <div className="course-close-button">
                    <Link to="/account/courses" className="btn btn-light">
                        <i className="fas fa-times fa-fw"/>
                    </Link>
                </div>
            </div>
            <div className="course-container">
                <CourseSidebar
                    course={ course }
                    mobile={ mobile }
                    onClose={ () => setMobileSidebarOpen(false) }
                    headerHeight={ headerHeight }
                    baseUrl={ baseUrl }
                />
                { !currentLesson ? (
                    <div className="course-content">
                        <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
                            <h1 className="text-primary">
                                <i className="fas fa-graduation-cap"/>
                            </h1>
                            <h3>
                                <Trans i18nKey="selectLesson"/>
                            </h3>
                            { mobile && (
                                <div>
                                    <Button
                                        variant="primary"
                                        onClick={ () => setMobileSidebarOpen(true) }
                                    >
                                        <Trans i18nKey="showLessons"/>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="course-content">
                        <div className="course-lesson-content">
                            { updateError && (
                                <Alert variant="danger">{ updateError }</Alert>
                            )}
                            <CourseLessonContent
                                course={ course }
                                chapter={ currentChapter }
                                lesson={ currentLesson }
                                mobile={ mobile }
                            />
                        </div>
                        <CourseLessonFooter
                            course={ course }
                            lesson={ currentLesson }
                            mobile={ mobile }
                            setCompleted={ updateLessonUserInfo }
                            completionSaving={ completionSaving }
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(AccountCourseDetail);
