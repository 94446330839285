import React from "react";
import {
    Link
} from "react-router-dom";

import ProductImage from "../ProductImage";

function ProductPageRelatedProducts() {
    return (
        <div className="row">
            {[...Array(6)].map((value, index) => (
                <div className="col-md-2" key={index}>
                    <Link to="/product">
                        <ProductImage image={ null }/>
                        <h6 className="mt-2">Product Title</h6>
                    </Link>
                    <p className="mb-0"><b>&euro; 59,99</b></p>
                    <p className="text-success">
                        <i className="fas fa-check-circle mr-2"/>
                        <b>Morgen in huis</b>
                    </p>
                </div>
            ))}
        </div>
    )
}

export default React.memo(ProductPageRelatedProducts);
