import React, {
    useEffect,
    useState
} from "react";
import {
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";
import Loading from "../../components/Loading";
import ProductCard from "../../components/product/ProductCard";

function RentalOrderStatusPageContent(props) {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);

    const getOrderStatus = () => {
        let orderToken = props.match.params.orderToken;
        setLoading(true);
        setError(null);
        shopcrateApi.post("/getRentalOrderStatus", { orderToken: orderToken })
            .then((response) => {
                if(response.data.valid) {
                    const status = response.data.status;
                    setStatus(status);
                    if(status === null || status === "open" || status === "pending") {
                        setTimeout(() => {
                            getOrderStatus();
                        }, 1000);
                    } else if(status === "paid") {
                        setLoading(false);
                        setProduct(response.data.product);
                    } else {
                        if(!response.data.product) {
                            setError(i18next.t("errorGeneral") + " (NO_PRODUCT)");
                            setLoading(false);
                        } else {
                            history.push("/rental-order/" + response.data.product.id + "/overview");
                            setLoading(false);
                        }
                    }
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch(() => {
                setError(i18next.t("errorGeneral"));
                setLoading(false);
            });
    }

    useEffect(() => {
        getOrderStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body text-center">
                    { status === "paid" ? (
                        <React.Fragment>
                            <i className="fad fa-calendar-alt text-primary mb-3" style={{ fontSize: "4rem" }}/>
                            <h4>
                                <Trans i18nKey="rentalOrderCompleteTitle"/>
                            </h4>
                            <p className="mb-0">
                                <Trans i18nKey="rentalOrderCompleteDescription"/>
                            </p>
                            <div className="mt-3">
                                { product && (
                                    <ProductCard product={ product } noPrice size="large"/>
                                )}
                            </div>
                        </React.Fragment>
                    ) : loading ? (
                        <React.Fragment>
                            <h4>
                                <Trans i18nKey="orderStatusLoading"/>
                            </h4>
                            <Loading/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h4>Klik op de knop hieronder om de status op te halen...</h4>
                            <button className="btn btn-success btn-lg" onClick={ getOrderStatus }>
                                Refresh
                            </button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(withRouter(RentalOrderStatusPageContent));
