import React, {
    useContext
} from "react";
import i18next from "i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductLimitPerOrderNotice() {
    const product = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!product.product || !shopContext.shop) {
        return null;
    }
    if(!shopContext.shop.orderingEnabled) {
        return null;
    }
    if(!product.product.orderable) {
        return null;
    }
    if(product.product.limitPerOrder === null || product.product.limitPerOrder <= 0) {
        return null;
    }
    if(product.product.stock !== null && product.product.limitPerOrder === product.product.stock) {
        return null;
    }
    return i18next.t("maxPerOrderNotice").replace("{amount}", product.product.limitPerOrder);
}

export default React.memo(ProductLimitPerOrderNotice);
