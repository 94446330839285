import React, {
    createRef,
    useLayoutEffect,
    useState
} from "react";
import useWindowSize from "../hooks/WindowSize";

function Footer() {
    let footerRef = createRef();
    const [height, setHeight] = useState(0);
    const size = useWindowSize();

    useLayoutEffect(() => {
        if (footerRef.current) {
            setHeight(footerRef.current.offsetHeight);
        }
    }, [footerRef, size]);

    const firstYear = 2020;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <div style={{ height: (height + 25) + "px" }}/>
            <div ref={ footerRef } className="jumbotron jumbotron-fluid jumbotron-footer">
                <div className="container">
                    <span className="d-none d-sm-block float-right">A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.</span>
                    <p>
                        Copyright &copy; { yearString } SR Productions. All rights reserved.{" "}
                        <span className="d-sm-none">A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.</span>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);
