import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import Loading from "../../../../components/Loading";
import OrderDetailContents from "../../../../components/order/OrderDetailContents";
import ShopContext from "../../../../context/internal/ShopManager";

function AccountOrderDetail({ match }) {
    const shopContext = useContext(ShopContext);
    const orderId = parseInt(match.params.orderId);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        const orderId = parseInt(match.params.orderId);
        if(!orderId || !shopApi) {
            return;
        }
        setOrder(null);
        setError(null);
        shopApi.post("/getOrder", { orderId })
            .then((response) => {
                if(response.data.valid) {
                    setOrder(response.data.order);
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            })
    }, [shopContext, match.params.orderId]);

    return (
        <React.Fragment>
            <Link to="/account/orders" className="btn btn-link px-0">
                <i className="fas fa-angle-left mr-2"/>
                <Trans i18nKey="backToOrderOverview"/>
            </Link>
            <h3>
                <Trans i18nKey="order"/> #{ orderId }
            </h3>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !order ? (
                <Loading/>
            ) : (
                <OrderDetailContents order={ order }/>
            )}
        </React.Fragment>
    );
}

export default withRouter(React.memo(AccountOrderDetail));
