import axios from "axios";
import {
    stringify
} from "qs";

if(!process.env.REACT_APP_SHOPCRATE_SHOP_CODENAME) {
    throw new Error("Environment variable REACT_APP_SHOPCRATE_SHOP_CODENAME is undefined.");
}

export const SHOPCRATE_API_URL = process.env.REACT_APP_SHOPCRATE_API_URL ? process.env.REACT_APP_SHOPCRATE_API_URL : "api.shopcrate.nl";
export const SHOPCRATE_SHOP_CODENAME = process.env.REACT_APP_SHOPCRATE_SHOP_CODENAME;
export const SHOPCRATE_API_SUB = "/v1/shop/" + SHOPCRATE_SHOP_CODENAME + "/";

const SHOPCRATE_API_BASEURL = (process.env.REACT_APP_ENVIRONMENT === "development" ? "" : "https://" + SHOPCRATE_API_URL) + SHOPCRATE_API_SUB

export function createAxios(baseURL) {
    return axios.create({
        baseURL: baseURL,
        withCredentials: true,
        transformRequest: (data) => {
            data = stringify(data);
            return data;
        }
    });
}
const shopcrateApi = createAxios(SHOPCRATE_API_BASEURL);

export default shopcrateApi;
