import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button,
    Spinner
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import AuthenticationContext from "../../../context/internal/AuthenticationManager";
import ShopContext from "../../../context/internal/ShopManager";

function DeliveryInfoAccountCard() {
    const authentication = useContext(AuthenticationContext);
    const shop = useContext(ShopContext);
    if(!shop.shop || !shop.shop.authenticationSupported || authentication.user === undefined) {
        return null;
    }
    if(authentication.user === null) {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <p className="card-text">
                        <Trans i18nKey="currentlyNotLoggedIn"/>
                        {" "}
                        <Link to="/order/login">
                            <Trans i18nKey="currentlyNotLoggedInButton"/>
                        </Link>
                    </p>
                </div>
            </div>
        )
    }
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex w-100 align-items-center flex-column flex-md-row">
                    <div className="flex-grow-1">
                        <Trans i18nKey="loggedInAs"/>
                        {" "}
                        <b>
                            { authentication.user.name }
                        </b>
                    </div>
                    <div className="ml-md-2 text-muted">
                        { authentication.user.email }
                    </div>
                    <div className="ml-md-2 mt-2 mt-md-0">
                        <Button
                            variant="secondary"
                            size="sm"
                            disabled={ authentication.loading }
                            onClick={ authentication.logout }
                        >
                            { authentication.loading && (
                                <Spinner animation="border" variant="white" size="sm" className="mr-2"/>
                            )}
                            <Trans i18nKey="logout"/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(DeliveryInfoAccountCard);
