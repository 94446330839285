import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Trans
} from "react-i18next";
import Skeleton from "react-loading-skeleton";

import {
    ProductPageContext
} from "../../context/ProductPageManager";

function ProductPageRentButton() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product) {
        return (
            <Skeleton/>
        )
    }
    return (
        <Link to={ "/rental-order/" + productContext.product.id + "/date" } className="btn btn-success pl-4 pr-4 pt-2 pb-2">
            <i className="fas fa-calendar-alt mr-2"/>
            <Trans i18nKey="rentNow"/>
        </Link>
    )
}

export default React.memo(ProductPageRentButton);
