import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import Skeleton from "react-loading-skeleton";
import i18next from "i18next";

import DigitalProductCard from "./components/DigitalProductCard";
import ShopContext from "../../../../context/internal/ShopManager";
import AccountEmptyStateCard from "../components/AccountEmptyStateCard";

function AccountDigitalProducts() {
    const shopContext = useContext(ShopContext);
    const [products, setProducts] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        if(!shopApi) {
            return;
        }
        setProducts(null);
        shopApi.post("/getDigitalProducts")
            .then((response) => {
                if(response.data.valid) {
                    setProducts(response.data.products);
                } else {
                    setError(i18next.t("errorGeneral"));
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            });
    }, [shopContext]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    return (
        <React.Fragment>
            <h3>
                <Trans i18nKey="digitalProducts"/>
            </h3>
            { !products ? (
                [...Array(10)].map((value, index) => (
                    <div key={ index } className="mb-3">
                        <Skeleton height={ 350 }/>
                    </div>
                ))
            ) : products.length === 0 ? (
                <AccountEmptyStateCard
                    title="noDigitalProductsYet"
                    description="noDigitalProductsYetDescription1"
                    description2="noDigitalProductsYetDescription2"
                />
            ) : products.map((product) => (
                <DigitalProductCard
                    product={ product }
                    key={ product.id }
                />
            ))}
        </React.Fragment>
    );
}

export default React.memo(AccountDigitalProducts);
