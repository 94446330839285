import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";

import ProductImage from "../ProductImage";
import PriceDisplay from "../PriceDisplay";

function CartCard({
    product,
    hideImage,
    noLink,
    amount,
    setProductCount,
    removeProduct
}) {
    const [loading, setLoading] = useState(false);
    const [countInput, setCountInput] = useState(0);

    useEffect(() => {
        if(!product) {
            return;
        }
        setCountInput(product.count);
    }, [product]);

    const setProductCountInternal = useMemo(() => {
        if(!setProductCount) {
            return null;
        }
        return async (count) => {
            setLoading(true);
            await setProductCount(product.id, count);
            setLoading(false);
        };
    }, [setProductCount, product]);
    const removeProductInternal = useMemo(() => {
        if(!removeProduct) {
            return null;
        }
        return async () => {
            setLoading(true);
            await removeProduct(product.id);
            setLoading(false);
        }
    }, [removeProduct, product]);
    const increaseProductCount = useMemo(() => {
        return async () => {
            await setProductCountInternal(product.count + 1);
        }
    }, [setProductCountInternal, product]);
    const decreaseProductCount = useMemo(() => {
        return async () => {
            await setProductCountInternal(product.count - 1);
        }
    }, [setProductCountInternal, product]);
    const applyCountInput = useMemo(() => {
        return async () => {
            const inputInt = parseInt(countInput);
            if(!inputInt) {
                setCountInput(product.count);
                return;
            }
            await setProductCountInternal(inputInt);
        }
    }, [setProductCountInternal, product, countInput]);
    const onInputKeyDown = useMemo(() => {
        return async (event) => {
            if(event.keyCode !== 13) { // Enter key
                return;
            }
            await applyCountInput();
        }
    }, [applyCountInput]);

    const productImage = useMemo(() => {
        return (
            <div
                className="d-flex justify-content-center align-content-center rounded"
                style={{ height: "120px", backgroundColor: "#f5f5f5" }}
            >
                <ProductImage
                    image={ product?.images?.[0] }
                    size="175x130"
                    className="img-fluid rounded"
                    alt="Product"
                    style={{ maxHeight: "120px" }}
                />
            </div>
        );
    }, [product]);

    const showLimitPerOrder = useMemo(() => {
        if(!product.limitPerOrder) {
            return false;
        }
        if(product.digital && amount === 1) {
            return false;
        }
        return amount >= product.limitPerOrder;
    }, [product, amount]);

    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="row">
                    { !hideImage && (
                        <div className="col-md-3">
                            { noLink ? productImage : (
                                <Link to={ "/product/" + product.id + "/" + product.url }>
                                    { productImage }
                                </Link>
                            )}
                        </div>
                    )}
                    <div className="col">
                        { hideImage ? (
                            <div className="d-flex flex-row align-items-center">
                                <div className="flex-grow-1 mr-3">
                                    <h5 className="m-0">
                                        { noLink ? product.name : (
                                            <Link to={ "/product/" + product.id + "/" + product.url }>
                                                { product.name }
                                            </Link>
                                        )}
                                    </h5>
                                </div>
                                <div style={{ whiteSpace: "nowrap" }}>
                                    <PriceDisplay product={ product } amount={ amount } stacked/>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <h5 className="card-title">
                                    { noLink ? product.name : (
                                        <Link to={ "/product/" + product.id + "/" + product.url }>
                                            { product.name }
                                        </Link>
                                    )}
                                </h5>
                                <span className="float-right text-right">
                                    <PriceDisplay product={ product } amount={ amount } stacked/>
                                </span>
                            </React.Fragment>
                        )}
                        { setProductCountInternal && removeProductInternal && (
                            <React.Fragment>
                                <div className="btn-toolbar align-items-center">
                                    { product.type.allowMultipleAmount && (
                                        <div className="btn-group mr-2">
                                            <Button
                                                variant="danger"
                                                onClick={ decreaseProductCount }
                                                disabled={ loading }
                                            >
                                                <i className="fas fa-minus"/>
                                            </Button>
                                            <input
                                                type="number" className="form-control" placeholder="Aantal"
                                                style={{ borderRadius: 0, width: "75px", textAlign: "center" }}
                                                value={ countInput }
                                                onChange={ (event) => setCountInput(event.target.value) }
                                                disabled={ loading }
                                                onKeyDown={ onInputKeyDown }
                                                onBlur={ applyCountInput }
                                            />
                                            <Button
                                                variant="success"
                                                onClick={ increaseProductCount }
                                                disabled={ loading || !product.allowedToIncreaseCount }
                                            >
                                                <i className="fas fa-plus"/>
                                            </Button>
                                        </div>
                                    )}
                                    <div className="btn-group">
                                        <Button
                                            variant="danger"
                                            onClick={ removeProductInternal }
                                            disabled={ loading }
                                        >
                                            <i className="fas fa-trash"/>
                                        </Button>
                                    </div>
                                    { product.type.value === "digital" ? (
                                        <span className="badge badge-pill badge-primary ml-2">
                                            <Trans i18nKey="digital"/>
                                        </span>
                                    ) : product.type.value === "course" && (
                                        <span className="badge badge-pill badge-primary ml-2">
                                            <Trans i18nKey="course"/>
                                        </span>
                                    )}
                                </div>
                                { showLimitPerOrder && (
                                    <div className="text-muted mt-2">
                                        { i18next.t("maxPerOrderNotice").replace("{amount}", product.limitPerOrder) }
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CartCard);
