import React from "react";
import {
    Trans
} from "react-i18next";

function OrderDetailData({ order }) {
    if(!order) {
        return null;
    }
    return (
        <React.Fragment>
            <div className="card mb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                            <h5 className="card-title">
                                <Trans i18nKey="contactStatusInformation"/>
                            </h5>
                            <span className="badge badge-pill badge-primary" style={{ backgroundColor: order.status.color }}>
                                { order.status.name }
                            </span>
                            <br/>
                            { order.email }
                            <br/>
                            { order.phoneNumber }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-3 mb-sm-0">
                            <h5 className="card-title">
                                <Trans i18nKey="deliveryAddress"/>
                            </h5>
                            { order.isCompany && (
                                <React.Fragment>
                                    { order.companyName }
                                    <br/>
                                </React.Fragment>
                            )}
                            { order.deliveryFirstName + " " + order.deliveryLastName }
                            <br/>
                            { order.deliveryStreet + " " + order.deliveryHouseNumber }
                            <br/>
                            { order.deliveryPostalCode + ", " + order.deliveryCity }
                            { (order.deliveryState || order.deliveryCountry) && (
                                <React.Fragment>
                                    <br/>
                                    { order.deliveryState + ", " + order.deliveryCountry.nameEnglish }
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 mb-3 mb-sm-0">
                            <h5 className="card-title">
                                <Trans i18nKey="invoiceAddress"/>
                            </h5>
                            { order.isCompany && (
                                <React.Fragment>
                                    { order.companyName }
                                    <br/>
                                </React.Fragment>
                            )}
                            { order.invoiceFirstName + " " + order.invoiceLastName }
                            <br/>
                            { order.invoiceStreet + " " + order.invoiceHouseNumber }
                            <br/>
                            { order.invoicePostalCode + ", " + order.invoiceCity }
                            { (order.invoiceState || order.invoiceCountry) && (
                                <React.Fragment>
                                    <br/>
                                    { order.invoiceState + ", " + order.invoiceCountry.nameEnglish }
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(OrderDetailData);
