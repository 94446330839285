import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Skeleton from "react-loading-skeleton";
import ShopContext from "../../context/internal/ShopManager";

function ProductPagePrice({ showCurrencySymbol = true }) {
    const productContext = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!productContext.product || !shopContext.shop) {
        return (
            <Skeleton/>
        )
    }
    if(!shopContext.shop.showPricing) {
        return null;
    }
    return (
        <React.Fragment>
            { showCurrencySymbol && <>&euro; </> }
            { productContext.getFormattedPrice().price },
            <sup style={{ marginLeft: "-5px" }}>
                <small>
                    { productContext.getFormattedPrice().cents }
                </small>
            </sup>
        </React.Fragment>
    )
}

export default React.memo(ProductPagePrice);
