import React from "react";

import Helmet from "../components/Helmet";
import DefaultProductPage from "../page-content/product/DefaultProductPage";
import Page404 from "./DefaultPage404";

function DefaultProductLayout() {
    return (
        <DefaultProductPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    )
}

export default React.memo(DefaultProductLayout);
