import React from "react";
import {
    Trans
} from "react-i18next";

import ProductPageManager, {
    ProductPageContext
} from "../../context/ProductPageManager";

import ProductImageCarousel from "../../components/product/ProductImageCarousel";
import ProductPageTitle from "../../components/product/ProductPageTitle";
import ProductPageDescription from "../../components/product/ProductPageDescription";
import ProductPagePrice from "../../components/product/ProductPagePrice";
import ProductPageRentButton from "../../components/product/ProductPageRentButton";

function DefaultRentalPage({ helmet, ...props }) {
    return (
        <ProductPageManager rental { ...props }>
            { helmet && (
                <ProductPageContext.Consumer>
                    { productContext => helmet(productContext) }
                </ProductPageContext.Consumer>
            )}
            <div className="container mt-5 mb-5">
                <ProductPageTitle/>

                <div className="row mt-5">
                    <div className="col-md-8">
                        <ProductImageCarousel/>
                    </div>
                    <div className="col-md-4">
                        <div className="text-danger">
                            <Trans i18nKey="fromPerDay"/>
                        </div>
                        <span className="h1 text-danger">
                            <ProductPagePrice/>
                        </span>
                        <br/><br/>
                        <ProductPageRentButton/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <h3 className="mt-5">
                            <Trans i18nKey="description"/>
                        </h3>
                        <ProductPageDescription/>
                    </div>
                </div>
            </div>
        </ProductPageManager>
    )
}

export default React.memo(DefaultRentalPage);
