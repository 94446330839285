import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";

function VerticalTabBarItem({to, title, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-link" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <Link to={ to } className={ className } onClick={ onClick }>
            { title }
        </Link>
    );
}

export default React.memo(VerticalTabBarItem);
