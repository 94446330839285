import React from "react";
import {
    Trans
} from "react-i18next";

import ProductPageManager, {
    ProductPageContext
} from "../../context/ProductPageManager";

import ProductImageCarousel from "../../components/product/ProductImageCarousel";
import ProductPageTitle from "../../components/product/ProductPageTitle";
import ProductPageAddToCardButton from "../../components/product/ProductPageAddToCardButton";
import ProductPageDescription from "../../components/product/ProductPageDescription";
import ProductLimitPerOrderNotice from "../../components/product/ProductLimitPerOrderNotice";
import ProductAvailabilityCheckmark from "../../components/product/ProductAvailabilityCheckmark";
import ProductEndDate from "../../components/product/ProductEndDate";
import ProductEndDateCountdown from "../../components/product/ProductEndDateCountdown";
import ProductPagePreorderNotice from "../../components/product/ProductPagePreorderNotice";
import ProductPagePriceDisplay from "../../components/product/ProductPagePriceDisplay";
import ProductPageStock from "../../components/product/ProductPageStock";
// import ProductPageSpecifications from "../../components/product/ProductPageSpecifications";
// import ProductPageRelatedProducts from "../../components/product/ProductPageRelatedProducts";

function DefaultProductPage({ helmet, ...props }) {
    return (
        <ProductPageManager { ...props }>
            { helmet && (
                <ProductPageContext.Consumer>
                    { productContext => helmet(productContext) }
                </ProductPageContext.Consumer>
            )}
            <div className="container mt-5 mb-5">
                <ProductPageTitle showSubtitle={ !!props.showSubtitle ? props.showSubtitle : true }/>

                <div className="row mt-5">
                    <div className="col-md-8">
                        <ProductImageCarousel/>
                    </div>
                    <div className="col-md-4">
                        <ProductPagePriceDisplay/>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            <b><ProductPageStock/></b>
                        </p>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            <b><ProductLimitPerOrderNotice/></b>
                        </p>
                        <h5 className="mt-2">
                            <ProductPagePreorderNotice/>
                        </h5>
                        <ProductAvailabilityCheckmark/>
                        <ProductPageAddToCardButton/>
                        <div className="mt-3">
                            <ProductEndDate/>
                            <div className="text-danger mt-1 text-monospace" style={{ fontSize: "1.2rem" }}>
                                <ProductEndDateCountdown/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <h3 className="mt-5">
                            <Trans i18nKey="description"/>
                        </h3>
                        <ProductPageDescription/>

                        {/*<h3 className="mt-5">Specificaties</h3>*/}
                        {/*<ProductPageSpecifications/>*/}
                    </div>
                </div>

                {/*<h3 className="mt-5">Gerelateerde producten</h3>*/}
                {/*<ProductPageRelatedProducts/>*/}
            </div>
        </ProductPageManager>
    )
}

export default React.memo(DefaultProductPage);
