import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Modal
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import ProductImage from "../ProductImage";
import PriceDisplay from "../PriceDisplay";
import CartContext from "../../context/internal/CartManager";

function AddedToCardModal(props) {
    const cartContext = useContext(CartContext);
    return (
        <Modal show={ props.show } onHide={ props.handleClose } size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    { cartContext.error ? (
                        <Trans i18nKey="error"/>
                    ) : (
                        <Trans i18nKey="addedToCart"/>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { cartContext.error ? (
                    <div className="d-flex flex-column justify-content-center">
                        <Alert variant="danger">{ cartContext.error }</Alert>
                        <div className="d-flex w-100 justify-content-center">
                            <button onClick={ props.handleClose } className="btn btn-secondary mt-2">
                                <Trans i18nKey="close"/>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-3 mb-3 mb-md-0">
                            <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
                                <ProductImage
                                    image={ props.product ? props.product.images[0] : null }
                                    size="175x130"
                                    className="img-fluid rounded"
                                    alt="Product"
                                    style={{ maxHeight: "120px" }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3 mb-md-0">
                            <span className="float-right text-right">
                                <PriceDisplay product={ props.product } stacked/>
                            </span>
                            <h4>{ props.product.name }</h4>
                            { props.product.subtitle.length > 0 && (
                                <h6 className="text-muted">{ props.product.subtitle }</h6>
                            )}
                        </div>
                        <div className="col-md-3">
                            <Link to="/cart" className="btn btn-success pl-4 pr-4 pt-2 pb-2 w-100 mb-2">
                                <i className="fas fa-shopping-cart mr-2"/>
                                <Trans i18nKey="toOrder"/>
                            </Link>
                            <button onClick={ props.handleClose } className="btn btn-secondary pl-4 pr-4 pt-2 pb-2 w-100">
                                <Trans i18nKey="continueShopping"/>
                            </button>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AddedToCardModal);
