import React, {
    useContext
} from "react";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import AuthenticationContext from "../../../context/internal/AuthenticationManager";
import AccountOverviewLastOrder from "./components/AccountOverviewLastOrder";

function AccountOverview() {
    const authentication = useContext(AuthenticationContext);

    const { user, logoutError } = authentication;

    return (
        <React.Fragment>
            { logoutError && (
                <Alert variant="danger">{ logoutError }</Alert>
            )}
            <h3>
                <Trans i18nKey="welcome"/> { user.name }
            </h3>
            <Button
                variant="link"
                onClick={ authentication.logout }
                disabled={ authentication.loading }
            >
                <Trans i18nKey="logout"/>
                { authentication.loading && (
                    <Spinner animation="border" variant="dark" size="sm" className="ml-2"/>
                )}
            </Button>
            <div className="mt-3">
                <AccountOverviewLastOrder/>
            </div>
        </React.Fragment>
    )
}

export default React.memo(AccountOverview);
