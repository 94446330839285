import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Trans
} from "react-i18next";

import ProductImage from "../../../../../components/ProductImage";

function DigitalProductCard({ product }) {
    return (
        <div className="card order-card mb-3">
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row">
                    <div className="mb-3 mb-md-0">
                        <div className="d-flex justify-content-center" style={{ width: "175px", maxHeight: "130px" }}>
                            <ProductImage
                                size="175x130"
                                image={ product.images[0] }
                                alt={ product.name }
                                style={{ maxWidth: "175px", maxHeight: "130px" }}
                                className="rounded"
                            />
                        </div>
                    </div>
                    <div className="flex-grow-1 ml-md-3">
                        <h5 className="card-title">
                            { product.name }
                        </h5>
                        <Link
                            to={`/account/digital-product/${product.id}`}
                            className="btn btn-link btn-sm px-0"
                        >
                            <Trans i18nKey="showProduct"/>
                            <i className="fas fa-angle-right ml-2"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(DigitalProductCard);
