import React from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";

function OrderDigitalProductLinkCard({ product }) {
    return (
        <div className="card mb-2" key={ product.id }>
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <div className="d-flex flex-column flex-grow-1 mb-2 mb-md-0">
                        <div className="mb-2">
                            <h5 className="card-title mb-0">
                                { product.name }
                            </h5>
                        </div>
                        <div>
                            <p className="card-text">
                                <Trans i18nKey="digitalProductAccountExplanation"/>
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to={ "/account/digital-product/" + product.id } className="btn btn-primary btn-sm">
                            <Trans i18nKey="showProduct"/>
                            <i className="fas fa-angle-right ml-2"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(OrderDigitalProductLinkCard);
