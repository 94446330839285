import React from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function usePreviousNextLesson(course, currentLesson) {
    let previousLesson = null;
    let nextLesson = null;
    let foundCurrent = false;
    for(const chapter of course.chapters) {
        for(const chapterLesson of chapter.lessons) {
            if(foundCurrent) {
                nextLesson = chapterLesson;
                break;
            }
            if(chapterLesson.id === currentLesson.id) {
                foundCurrent = true;
            } else {
                previousLesson = chapterLesson;
            }
        }
        if(nextLesson) {
            break;
        }
    }
    return {
        previousLesson,
        nextLesson
    }
}

function CourseLessonCompletionButton({ course, lesson, setCompleted, disabled }) {
    const button = (
        <Button
            variant="light"
            onClick={ () => setCompleted(!lesson.completed) }
            disabled={ disabled || course.demoMode }
        >
            { lesson.completed ? (
                <React.Fragment>
                    <i className="fas fa-times-circle mr-2"/>
                    <Trans i18nKey="markUncompleted"/>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <i className="fas fa-check-circle mr-2"/>
                    <Trans i18nKey="markCompleted"/>
                </React.Fragment>
            )}
        </Button>
    );
    if(course.demoMode) {
        return (
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="demo-mode">
                    <Trans i18nKey="courseProgressDisabledDemoMode"/>
                </Tooltip>
            }>
                { button }
            </OverlayTrigger>
        )
    }
    return button;
}

function CourseLessonFooter({ course, lesson, mobile, setCompleted, completionSaving }) {
    const {
        previousLesson,
        nextLesson
    } = usePreviousNextLesson(course, lesson);
    return (
        <div className="course-content-footer d-flex flex-column">
            { mobile && (
                <div className="mb-3 d-flex justify-content-center">
                    <CourseLessonCompletionButton
                        course={ course }
                        lesson={ lesson }
                        setCompleted={ setCompleted }
                        disabled={ completionSaving }
                    />
                </div>
            )}
            <div className="d-flex flex-row">
                <div className="flex-grow-1">
                    { previousLesson && (
                        <Link
                            to={ `/account/course/${ course.id }/lesson/${ previousLesson.id }` }
                            className="btn btn-light"
                        >
                            <i className="fas fa-chevron-left mr-2"/>
                            <Trans i18nKey={ mobile ? "previous" : "previousLesson" }/>
                        </Link>
                    )}
                </div>
                { !mobile && (
                    <div className="mr-2">
                        <CourseLessonCompletionButton
                            course={ course }
                            lesson={ lesson }
                            setCompleted={ setCompleted }
                            disabled={ completionSaving }
                        />
                    </div>
                )}
                <div>
                    { nextLesson && (
                        <Link
                            to={ `/account/course/${ course.id }/lesson/${ nextLesson.id }` }
                            className="btn btn-primary"
                        >
                            <Trans i18nKey={ mobile ? "next" : "nextLesson" }/>
                            <i className="fas fa-chevron-right ml-2"/>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(CourseLessonFooter);
