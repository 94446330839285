import React, {
    useContext
} from "react";
import i18next from "i18next";
import {
    Route,
    Switch,
    withRouter
} from "react-router-dom";

import VerticalTabBar from "../../components/tabbar/VerticalTabBar";
import VerticalTabBarItem from "../../components/tabbar/VerticalTabBarItem";
import ShopContext from "../../context/internal/ShopManager";

import AccountOverview from "./tabs/AccountOverview";
import AccountOrders from "./tabs/orders/AccountOrders";
import AccountDigitalProducts from "./tabs/digital-products/AccountDigitalProducts";
import AccountCourses from "./tabs/courses/AccountCourses";

function AccountTabs({ match }) {
    const shopContext = useContext(ShopContext);
    return (
        <div className="row">
            <div className="col-md-3 col-xl-2 mb-4">
                <VerticalTabBar>
                    <VerticalTabBarItem
                        to={`${match.path}`}
                        title={ i18next.t("overview") }
                        exact
                    />
                    <VerticalTabBarItem
                        to={`${match.path}/orders`}
                        title={ i18next.t("orders") }
                    />
                    <VerticalTabBarItem
                        to={`${match.path}/digital-products`}
                        title={ i18next.t("digitalProducts") }
                    />
                    { shopContext.shop.coursesEnabled && (
                        <VerticalTabBarItem
                            to={`${match.path}/courses`}
                            title={ i18next.t("courses") }
                        />
                    )}
                </VerticalTabBar>
            </div>
            <div className="col-md-9 col-xl-10">
                <Switch>
                    <Route path={`${match.path}`} exact component={AccountOverview}/>
                    <Route path={`${match.path}/orders`} exact component={AccountOrders}/>
                    <Route path={`${match.path}/orders/:page`} exact component={AccountOrders}/>
                    <Route path={`${match.path}/digital-products`} exact component={AccountDigitalProducts}/>
                    { shopContext.shop.coursesEnabled && (
                        <Route path={`${match.path}/courses`} exact component={AccountCourses}/>
                    )}
                </Switch>
            </div>
        </div>
    )
}

export default withRouter(React.memo(AccountTabs));
