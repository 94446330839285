import React from "react";

function VerticalTabBar({ children }) {
    return (
        <div className="nav flex-column nav-pills">
            { children }
        </div>
    )
}

export default React.memo(VerticalTabBar);
