import React from "react";

import OrderProductCard from "../product-cards/OrderProductCard";
import OrderPriceSummary from "./OrderPriceSummary";
import OrderDetailProductContentList from "./OrderDetailProductContentList";
import OrderDetailData from "./OrderDetailData";

function OrderDetailContents({ order }) {
    if(!order) {
        return null;
    }
    return (
        <React.Fragment>
            <OrderDetailData
                order={ order }
            />

            <h4 className="mt-3">
                Producten
            </h4>
            { order.products.map((product) => (
                <OrderProductCard
                    key={ product.id }
                    product={ product }
                />
            ))}
            <OrderPriceSummary order={ order }/>

            <OrderDetailProductContentList
                order={ order }
            />
        </React.Fragment>
    )
}

export default React.memo(OrderDetailContents);
