import React, {
    useEffect,
    useState
} from "react";
import {
    useParams
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import shopCrateAPI from "../ShopCrateAPI";
import Loading from "../components/Loading";
import DigitalProductLinksList from "../components/order/DigitalProductLinksList";

function DigitalProductDemoPageContent() {
    const params = useParams();

    const [links, setLinks] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!params.token) {
            return;
        }
        setError(null);
        shopCrateAPI.post("/getDigitalProductWithToken", { token: params.token })
            .then((response) => {
                if(response.data.valid) {
                    setLinks(response.data.links);
                } else {
                    setError( i18next.t("errorGeneral") + " (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            });
    }, [params.token]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!links) {
        return (
            <Loading/>
        )
    }
    if(links.length === 0) {
        return (
            <Alert variant="danger">
                <Trans i18nKey="productWithoutLinks"/>
            </Alert>
        )
    }
    return (
        <DigitalProductLinksList
            links={ links }
        />
    );
}

export default React.memo(DigitalProductDemoPageContent);
