import React from "react";

import DigitalProductLinkCard from "./DigitalProductLinkCard";

function DigitalProductLinksList({ links }) {
    return links.map((link) => (
        <DigitalProductLinkCard
            link={ link }
            key={ link.id }
        />
    ));
}

export default React.memo(DigitalProductLinksList);
