import React from "react";
import {
    Trans
} from "react-i18next";

import PriceFormatter from "../PriceFormatter";

function OrderPriceSummary({ order }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex justify-content-end text-right mb-2">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="shippingCosts"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            { order.shippingPrice === null ? (
                                <Trans i18nKey="selectCountry"/>
                            ) : order.shippingPrice === 0 ? (
                                <Trans i18nKey="free"/>
                            ) : (
                                <PriceFormatter price={ order.shippingPrice }/>
                            ) }
                        </b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="vat"/>
                        </b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <PriceFormatter price={ order.totalTaxPrice }/>
                        </b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right">
                    <div style={{ width: "150px" }}>
                        <b>
                            <Trans i18nKey="totalPrice"/>
                        </b>
                        <br/>
                        <span className="text-muted">
                            <Trans i18nKey="includingVat"/>
                        </span>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <PriceFormatter price={ order.totalPrice }/>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(OrderPriceSummary);
