import React from "react";

import ProductManager, {
    ProductContext
} from "../context/ProductManager";
import Countdown from "./Countdown";
import Loading from "./Loading";

function ProductReleaseCountdown({ productId, children, unreleased }) {
    if(!productId) {
        console.error("No productId provided to ProductReleaseCountdown");
        return null;
    }
    return (
        <ProductManager productId={ productId }>
            <ProductContext.Consumer>{(context) => {
                if(context.error === null && context.product === null) {
                    return (
                        <Loading/>
                    )
                }
                if(context.errorCode === "NOT_RELEASED_YET") {
                    return unreleased(
                        <Countdown date={ context.releaseDate }/>
                    );
                } else {
                    return children;
                }
            }}</ProductContext.Consumer>
        </ProductManager>
    )
}

export default React.memo(ProductReleaseCountdown);
