import React from "react";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ProductCollectionBase from "./ProductCollectionBase";
import ProductCard from "../product/ProductCard";
import {
    withShopContext
} from "../../context/internal/ShopManager";

export class ProductCollectionBarInternal extends ProductCollectionBase {
    getProductCard(product) {
        return (
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2">
                <ProductCard product={ product }/>
            </div>
        )
    }

    render() {
        const {
            error,
            collection
        } = this.state;
        const {
            shopContext,
            noTitle
        } = this.props;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        if(!collection || !shopContext.shop) {
            return (
                <div className="mb-3">
                    { !this.props.noTitle && (
                        <Skeleton height={ 30 } width={ 200 }/>
                    )}
                    <div className="row mt-1">
                        {[...Array(6)].map((value, index) => (
                            <div className="col-md-2" key={index}>
                                <Skeleton height={ 125 }/>
                                <div className="mb-1">
                                    <Skeleton height={ 19 } width={ 100 }/>
                                </div>
                                <Skeleton height={ 19 } width={ 60 }/>
                                <Skeleton height={ 19 }/>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
        return (
            <div>
                { !noTitle && (
                    <h3>{ collection.name }</h3>
                )}
                <div className="row">
                    { collection.products.map((product, index) => (
                        <React.Fragment key={ index }>
                            { this.getProductCard(product) }
                        </React.Fragment>
                    ))}
                </div>
            </div>
        )
    }
}

const ProductCollectionBar = withShopContext(ProductCollectionBarInternal);

export default ProductCollectionBar;
