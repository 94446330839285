import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";

import ProductImage from "../ProductImage";
import ShopContext from "../../context/internal/ShopManager";
import PriceFormatter from "../PriceFormatter";
import ProductImagePlaceholder from "../ProductImagePlaceholder";
import PriceDisplay from "../PriceDisplay";

function ProductCard({ product, size = "small", noLink = false, noPrice = false }) {
    const shopContext = useContext(ShopContext);
    if(!shopContext.shop) {
        return null;
    }
    const url = "/" + (product.rental ? "rental" : "product") + "/" + product.id + "/" + product.url;
    if(size === "small") {
        const image = product.images.length === 0 ? null : product.images[0];
        const content = (
            <React.Fragment>
                <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
                    <ProductImage
                        image={ image }
                        size="175x130"
                        className="img-fluid rounded"
                        alt="Product"
                        style={{ objectFit: "contain" }}
                    />
                </div>
                <h6 className="mt-2">{ product.name }</h6>
            </React.Fragment>
        )
        return (
            <div>
                { noLink ? content : (
                    <Link to={ url }>
                        { content }
                    </Link>
                )}
                { shopContext.shop.showPricing && !noPrice && (
                    <p className="mb-0">
                        <PriceDisplay product={ product }/>
                    </p>
                )}
                { shopContext.shop.orderingEnabled && (
                    <p className="text-success">
                        <i className="fas fa-check-circle mr-2"/>
                        { product.digital ? (
                            <b>Direct beschikbaar</b>
                        ) : (
                            <b>Morgen in huis</b>
                        )}
                    </p>
                )}
            </div>
        )
    } else if(size === "large") {
        const image = product.images.length === 0 ? null : product.images[0];
        const content = (
            <React.Fragment>
                <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "250px", backgroundColor: "white" }}>
                    { image ? (
                        <img
                            src={ image.url }
                            className="img-fluid rounded"
                            alt="Product"
                            style={{ objectFit: "contain" }}
                        />
                    ) : (
                        <ProductImagePlaceholder/>
                    )}
                </div>
                <h6 className="mt-2">{ product.name }</h6>
            </React.Fragment>
        )
        return (
            <div className="text-center">
                { noLink ? content : (
                    <Link to={ url }>
                        { content }
                    </Link>
                )}
                { shopContext.shop.showPricing && !noPrice && (
                    <p className="mb-0">
                        <PriceDisplay product={ product }/>
                    </p>
                )}
            </div>
        )
    }
}

export default React.memo(ProductCard);
