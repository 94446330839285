import React, {
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";

function OrderCompleteCard({ order }) {
    const productTypeValues = useMemo(() => {
        if(!order || !order.productTypes) {
            return [];
        }
        return order.productTypes.map((productType) => {
            return productType.value;
        });
    }, [order]);
    return (
        <div className="card mb-3">
            <div className="card-body text-center text-md-left">
                <div className="d-flex w-100 align-items-center justify-content-center flex-column flex-md-row">
                    <div className="mb-3 mb-md-0 mr-md-4">
                        <i className="fad fa-box-alt text-primary" style={{ fontSize: "4rem" }}/>
                    </div>
                    <div>
                        <h4 className="card-title mb-1">
                            <Trans i18nKey="orderCompleteTitle"/>
                        </h4>
                        <p className="mb-0" style={{ maxWidth: "375px" }}>
                            { productTypeValues.includes("physical") && productTypeValues.includes("digital") ? (
                                <Trans i18nKey="orderCompleteDescriptionPhysicalDigital"/>
                            ) : productTypeValues.includes("physical") ? (
                                <Trans i18nKey="orderCompleteDescriptionPhysical"/>
                            ) : productTypeValues.includes("digital") && (
                                <Trans i18nKey="orderCompleteDescriptionDigital"/>
                            )}
                        </p>
                        <p className="mb-0" style={{ maxWidth: "375px" }}>
                            { productTypeValues.includes("course") && (
                                <Trans i18nKey="orderCompleteDescriptionCourse"/>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(OrderCompleteCard);
