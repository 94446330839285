import React from "react";

import PriceDisplay from "../PriceDisplay";

function CartCardSmall({ product }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex flex-row align-items-center">
                    { product.count && (
                        <div className="text-muted mr-3">
                            <b>
                                { product.count }x
                            </b>
                        </div>
                    )}
                    <h5 className="card-title mb-0 flex-grow-1">
                        { product.name }
                    </h5>
                    <div className="text-muted text-right">
                        <PriceDisplay product={ product } amount={ product.count } stacked/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CartCardSmall);
