import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import i18next from "i18next";
import {
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Spinner
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import AuthenticationContext from "../../context/internal/AuthenticationManager";
import Loading from "../../components/Loading";
import shopcrateApi from "../../ShopCrateAPI";

function ResetPasswordPageContent({ match }) {
    const authentication = useContext(AuthenticationContext);
    const [valid, setValid] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [initialError, setInitialError] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = match.params.token;
        setInitialError(null);
        setValid(null);
        shopcrateApi.post("/checkResetPasswordToken", { token })
            .then((response) => {
                if(response.data.valid) {
                    setValid(true);
                } else {
                    setValid(false);
                    setInitialError(i18next.t("invalidPasswordResetLink"));
                }
            })
            .catch((error) => {
                console.error(error);
                setValid(false);
                setInitialError(i18next.t("errorGeneral"));
            });
    }, [match]);
    const onSubmit = useMemo(() => {
        return (event) => {
            event.preventDefault();
            const token = match.params.token;
            setError(null);
            setLoading(true);
            shopcrateApi.post("/resetPassword", { token, password, passwordConfirm })
                .then((response) => {
                    if(response.data.valid) {
                        setSuccess(true);
                    } else {
                        const error = response.data.error;
                        switch(error) {
                        case "PASSWORD_MISMATCH":
                            setError(i18next.t("errorPasswordMismatch"));
                            break;
                        case "PASSWORD_INSECURE":
                            setError(i18next.t("errorPasswordInsecure"));
                            break;
                        default:
                            setError(i18next.t("errorGeneral") + " (" + error + ")");
                            break;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setValid(false);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [match, password, passwordConfirm]);

    const authenticationStatus = useMemo(() => {
        return authentication.getAuthenticationStatus();
    }, [authentication]);

    if(authenticationStatus === "unsupported") {
        return (
            <Alert variant="danger">
                <Trans i18nKey="errorAuthenticationUnsupported"/>
            </Alert>
        )
    }
    if(valid === null || authenticationStatus === "unknown") {
        return (
            <Loading/>
        );
    }
    if(authenticationStatus === "loggedin") {
        return (
            <Redirect to="/account"/>
        )
    }
    if(initialError) {
        return (
            <Alert variant="danger">
                { initialError }
            </Alert>
        )
    }
    return (
        <div
            className="py-4"
            style={{ maxWidth: "350px", marginLeft: "auto", marginRight: "auto" }}
        >
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success ? (
                <React.Fragment>
                    <Alert variant="success">
                        <Trans i18nKey="resetPasswordSuccess"/>
                    </Alert>
                    <div className="mt-3 d-flex justify-content-center">
                        <div>
                            <Link to="/login" className="btn btn-primary px-4">
                                <Trans i18nKey="returnToLogin"/>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <form onSubmit={ onSubmit }>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder={ i18next.t("password") }
                                disabled={ loading }
                                value={ password }
                                onChange={(event) => setPassword(event.target.value) }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id="passwordConfirm"
                                placeholder={ i18next.t("passwordConfirm") }
                                disabled={ loading }
                                value={ passwordConfirm }
                                onChange={(event) => setPasswordConfirm(event.target.value) }
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-primary px-5"
                                disabled={ loading }
                            >
                                { loading ? (
                                    <Spinner animation="border" variant="dark" size="sm"/>
                                ) : (
                                    <Trans i18nKey="submit"/>
                                )}
                            </button>
                        </div>
                    </form>
                    <div className="mt-4 d-flex justify-content-center">
                        <div>
                            <Link to="/login">
                                <Trans i18nKey="returnToLogin"/>
                            </Link>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default withRouter(React.memo(ResetPasswordPageContent));
