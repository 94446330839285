import React from "react";

function ProductImagePlaceholder() {
    return (
        <div className="product-image-placeholder-container">
            <div className="product-image-placeholder-content">
                <i className="fas fa-image"/>
            </div>
        </div>
    )
}

export default React.memo(ProductImagePlaceholder);
