import {
    useEffect,
    useState
} from "react";

function useHideBodyScroll() {
    const [enabled, setEnabled] = useState(true);
    useEffect(() => {
        if(enabled) {
            document.body.classList.add("disable-scroll");
        } else {
            document.body.classList.remove("disable-scroll");
        }
        return () => {
            document.body.classList.remove("disable-scroll");
        }
    }, [enabled]);
    return setEnabled;
}

export default useHideBodyScroll;
