import React, {
    useContext,
    useEffect,
    useState
} from "react";
import i18next from "i18next";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import Skeleton from "react-loading-skeleton";

import ShopContext from "../../../../context/internal/ShopManager";
import OrderCard from "../orders/components/OrderCard";
import AccountEmptyStateCard from "./AccountEmptyStateCard";

function AccountOverviewLastOrder() {
    const shopContext = useContext(ShopContext);
    const [lastOrder, setLastOrder] = useState(undefined);
    const [error, setError] = useState(null);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        if(!shopApi) {
            return;
        }
        setLastOrder(undefined);
        shopApi.post("/getOrders", { page: 1, pageSize: 1 })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.total === 0) {
                        setLastOrder(null);
                    } else {
                        setLastOrder(response.data.orders[0]);
                    }
                } else {
                    setError(i18next.t("errorGeneral"));
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            });
    }, [shopContext]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(lastOrder === undefined) {
        return (
            <React.Fragment>
                <h4>
                    <Skeleton width={ 200 }/>
                </h4>
                <Skeleton height={ 300 }/>
            </React.Fragment>
        )
    }
    if(lastOrder === null) {
        return (
            <AccountEmptyStateCard
                title="noOrdersYet"
                description="noOrdersYetDescription1"
                description2="noOrdersYetDescription2"
            />
        )
    }
    return (
        <React.Fragment>
            <h4>
                <Trans i18nKey="lastOrder"/>
            </h4>
            <OrderCard order={ lastOrder }/>
            <div className="d-flex justify-content-center">
                <Link to="/account/orders" className="btn btn-primary">
                    <Trans i18nKey="showAllOrders"/>
                </Link>
            </div>
        </React.Fragment>
    )
}

export default React.memo(AccountOverviewLastOrder);
