import React from "react";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";
import {
    withShopContext
} from "./ShopManager";
import {
    withAuthenticationContext
} from "./AuthenticationManager";
import {
    withCartContext
} from "./CartManager";
import {
    withPaymentMethodContext
} from "./PaymentMethodManager";

const OrderContext = React.createContext(null);

class OrderManagerInternal extends React.Component {
    getDefaultDeliveryInfo() {
        return {
            company: false,
            companyName: "",

            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryStreet: "",
            deliveryHouseNumber: "",
            deliveryPostalCode: "",
            deliveryCity: "",
            deliveryState: "",
            deliveryCountry: "",

            invoiceEqualsDelivery: true,
            invoiceFirstName: "",
            invoiceLastName: "",
            invoiceStreet: "",
            invoiceHouseNumber: "",
            invoicePostalCode: "",
            invoiceCity: "",
            invoiceState: "",
            invoiceCountry: "",

            email: "",
            phoneNumber: "",

            newsletterSignup: false
        };
    }

    constructor(props) {
        super(props);
        const deliveryInfo = localStorage.deliveryInfo !== undefined ? JSON.parse(localStorage.deliveryInfo) : {};
        this.state = {
            orderContext: {
                deliveryInfo: { ...this.getDefaultDeliveryInfo(), ...deliveryInfo },
                countries: null,
                countriesError: null,
                shippingPrice: null,
                orderError: null,
                updateDeliveryInfo: this.updateDeliveryInfo.bind(this),
                isDeliveryInfoValid: this.isDeliveryInfoValid.bind(this),
                getCountries: this.getCountries.bind(this),
                getShippingPrice: this.getShippingPrice.bind(this),
                isLoading: this.isLoading.bind(this),
                placeOrder: this.placeOrder.bind(this)
            }
        }
    }

    componentDidMount() {
        this.updateShippingPrice();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.shopContext.shop !== prevProps.shopContext.shop) {
            this.updateShippingPrice();
        } else if(this.state.orderContext.deliveryInfo.deliveryCountry !== prevState.orderContext.deliveryInfo.deliveryCountry) {
            this.updateShippingPrice();
        } else if(this.state.orderContext.countries !== prevState.orderContext.countries) {
            this.updateShippingPrice();
        }
        if(this.props.authenticationContext.user && prevProps.authenticationContext.user !== this.props.authenticationContext.user) {
            this.updateDeliveryInfo({ email: this.props.authenticationContext.user.email });
        }
    }

    updateOrderContext(state) {
        this.setState(previousState => {
            return { orderContext: { ...previousState.orderContext, ...state } }
        });
    }

    setDeliveryInfo(deliveryInfo) {
        localStorage.deliveryInfo = JSON.stringify(deliveryInfo);
        this.updateOrderContext({ deliveryInfo: deliveryInfo });
    }

    updateDeliveryInfo(updatedField) {
        let deliveryInfo = this.state.orderContext.deliveryInfo;
        this.setDeliveryInfo({ ...deliveryInfo, ...updatedField });
    }

    isDeliveryInfoValid() {
        const shop = this.props.shopContext.shop;

        const state = this.state.orderContext.deliveryInfo;
        if(state.company && state.companyName.length === 0) return false;
        if(state.deliveryFirstName.length === 0) return false;
        if(state.deliveryLastName.length === 0) return false;
        if(state.deliveryStreet.length === 0) return false;
        if(state.deliveryHouseNumber.length === 0) return false;
        if(state.deliveryPostalCode.length === 0) return false;
        if(state.deliveryCity.length === 0) return false;
        if(shop && shop.shippingCosts) {
            if(state.deliveryState.length === 0) return false;
            if(state.deliveryCountry.length === 0) return false;
        }

        if(!state.invoiceEqualsDelivery) {
            if(state.invoiceFirstName.length === 0) return false;
            if(state.invoiceLastName.length === 0) return false;
            if(state.invoiceStreet.length === 0) return false;
            if(state.invoiceHouseNumber.length === 0) return false;
            if(state.invoicePostalCode.length === 0) return false;
            if(state.invoiceCity.length === 0) return false;
            if(shop && shop.shippingCosts) {
                if(state.invoiceState.length === 0) return false;
                if(state.invoiceCountry.length === 0) return false;
            }
        }

        if(!this.props.authenticationContext.user) {
            if(state.email.length === 0) return false;
        }
        if(state.phoneNumber.length === 0) return false;

        return true;
    }

    updateShippingPrice() {
        this.updateOrderContext({ shippingPrice: this.getShippingPrice() });
    }

    getShippingPrice() {
        const shop = this.props.shopContext.shop;
        if(!shop || !shop.shippingCosts) {
            return 0;
        }
        const deliveryCountryCode = this.state.orderContext.deliveryInfo.deliveryCountry;
        if(!deliveryCountryCode || this.state.orderContext.countries === null) {
            return null;
        }
        const country = this.state.orderContext.countries.find((country) => {
            return country.iso === deliveryCountryCode;
        });
        if(!country) {
            return null;
        }
        return country.shippingPrice;
    }

    isLoading() {
        if(this.props.shopContext.shop === null) {
            return true;
        }
        if(!this.props.shopContext.shop.shippingCosts) {
            return false;
        }
        return !this.state.orderContext.countries;
    }

    getCountries() {
        if(this.loadingCountries) {
            return;
        }
        this.loadingCountries = true;
        shopcrateApi.get("/getCountries")
            .then((response) => {
                if(response.data.valid) {
                    const countries = response.data.countries;
                    this.updateOrderContext({ countries }, () => {
                        this.updateShippingPrice();
                    });
                } else {
                    this.updateOrderContext({ countriesError: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.updateOrderContext({ countriesError: i18next.t("errorGeneral") });
            })
            .finally(() => {
                this.loadingCountries = false;
            })
    }

    async placeOrder() {
        const {
            shop,
            shopApi
        } = this.props.shopContext;
        if(!shop) {
            return false;
        }
        const axios = shopApi ? shopApi : shopcrateApi;
        try {
            const response = await axios.post("/placeOrder", {
                deliveryInfo: JSON.stringify(this.state.orderContext.deliveryInfo),
                cartToken: this.props.cartContext.cartToken,
                paymentMethod: this.props.paymentMethodContext.paymentMethod,
                language: i18next.language
            });
            if(response.data.valid) {
                window.location = response.data.checkoutUrl;
                return { success: true };
            } else {
                switch(response.data.error) {
                case "INVALID_CART_CONTENT":
                    this.props.cartContext.refreshCart();
                    return { success: false, errorMessage: i18next.t("errorInvalidCartContent") };
                default:
                    return { success: false, errorMessage: i18next.t("errorGeneralPlaceOrder") + " (" + response.data.error + ")" };
                }
            }
        } catch(error) {
            console.error(error);
            return { success: false, errorMessage: i18next.t("errorGeneralPlaceOrder") };
        }
    }

    render() {
        return (
            <OrderContext.Provider value={ this.state.orderContext }>
                {this.props.children}
            </OrderContext.Provider>
        )
    }
}

export const OrderManager = withAuthenticationContext(withShopContext(withCartContext(withPaymentMethodContext(OrderManagerInternal))));

export default OrderContext;
