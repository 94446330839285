import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    useShopCrateRoutes
} from "@shopcrate/shopcrate-framework";

import Navbar from "./components/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";

function ShopRouter() {
    const shopCrateRoutes = useShopCrateRoutes();
    return (
        <React.Fragment>
            <Navbar/>
            <ScrollToTop/>

            <Switch>
                <Route path="/" exact component={Home}/>

                { shopCrateRoutes }

                <Route path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                    <Redirect to="/"/>
                </Route>
                <Route path="/" component={Page404}/>
            </Switch>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
