import React, {
    useMemo
} from "react";
import PriceFormatter from "./PriceFormatter";

function PriceDisplay({ amount = 1, product, stacked = false }) {
    const currentPrice = useMemo(() => {
        if(!product) {
            return null;
        }
        if(product.orderPrice) {
            return product.orderPrice;
        }
        if(product.discountPrice) {
            return product.discountPrice;
        }
        return product.price;
    }, [product]);
    if(!product) {
        return null;
    }
    return (
        <React.Fragment>
            { !product.orderPrice && product.discountPrice && (
                <React.Fragment>
                    <s
                        className={ "text-muted" + (stacked ? "" : " mr-1") }
                        style={{ fontSize: "0.9rem" }}
                    >
                        <PriceFormatter price={ amount * product.price }/>
                    </s>
                    { stacked && (
                        <br/>
                    )}
                </React.Fragment>
            )}
            <b>
                <PriceFormatter price={ amount * currentPrice }/>
            </b>
        </React.Fragment>
    )
}

export default React.memo(PriceDisplay);
