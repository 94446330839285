import React from "react";
import "react-dates/initialize";
import {
    I18nextProvider
} from "react-i18next";

import {
    ShopManager
} from "./internal/ShopManager";
import ShopCrateLocalization from "../ShopCrateLocalization";
import {
    AuthenticationManager
} from "./internal/AuthenticationManager";
import {
    CartManager
} from "./internal/CartManager";
import {
    OrderManager
} from "./internal/OrderManager";
import {
    RentalManager
} from "./internal/RentalManager";
import {
    PaymentMethodManager
} from "./internal/PaymentMethodManager";

function ShopCrateManager({ children }) {
    return (
        <I18nextProvider i18n={ ShopCrateLocalization }>
            <ShopManager>
                <AuthenticationManager>
                    <CartManager>
                        <PaymentMethodManager>
                            <OrderManager>
                                <RentalManager>
                                    { children }
                                </RentalManager>
                            </OrderManager>
                        </PaymentMethodManager>
                    </CartManager>
                </AuthenticationManager>
            </ShopManager>
        </I18nextProvider>
    )
}

export default ShopCrateManager;
