import React, {
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import ReactPlayer from "react-player";

import useWindowSize from "../hooks/WindowSize";

function VideoPlayer({ url }) {
    const containerRef = useRef();
    const windowSize = useWindowSize();
    const [width, setWidth] = useState(550);
    useEffect(() => {
        if(!containerRef.current) {
            return;
        }
        const maxWidth = 640;
        setWidth(Math.min(maxWidth, containerRef.current.offsetWidth));
    }, [containerRef, windowSize]);
    const height = useMemo(() => {
        return width / 16 * 9;
    }, [width]);
    return (
        <div ref={ containerRef }>
            <ReactPlayer
                url={ url }
                controls
                width={ width }
                height={ height }
            />
        </div>
    );
}

export default React.memo(VideoPlayer);
