import React, {
    useContext,
    useMemo
} from "react";
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from "react-router-dom";

import AuthenticationContext from "../../context/internal/AuthenticationManager";
import Loading from "../../components/Loading";

import AccountTabs from "./AccountTabs";
import AccountOrderDetail from "./tabs/orders/AccountOrderDetail";
import AccountDigitalProductDetail from "./tabs/digital-products/AccountDigitalProductDetail";
import AccountCourseDetailWrapper from "./tabs/courses/AccountCourseDetailWrapper";

function AccountPageContent({ match }) {
    const authentication = useContext(AuthenticationContext);

    const authenticationStatus = useMemo(() => {
        return authentication.getAuthenticationStatus();
    }, [authentication]);

    if(authenticationStatus === "unsupported" || authenticationStatus === "nosession") {
        return (
            <Redirect to="/login"/>
        )
    }
    if(authenticationStatus === "unknown") {
        return (
            <Loading/>
        );
    }

    return (
        <Switch>
            <Route path={`${match.path}/order/:orderId`} exact component={AccountOrderDetail}/>
            <Route path={`${match.path}/digital-product/:productId`} exact component={AccountDigitalProductDetail}/>
            <Route
                path={[`${match.path}/course/:courseId`, `${match.path}/course/:courseId/lesson/:lessonId`]}
                exact
                component={AccountCourseDetailWrapper}
            />
            <Route path={`${match.path}`} component={AccountTabs}/>
        </Switch>
    )
}

export default withRouter(React.memo(AccountPageContent));
