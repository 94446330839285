import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown";

function ProductPageDescription() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product) {
        return (
            <Skeleton count={ 10 }/>
        )
    }
    return (
        <ReactMarkdown>
            { productContext.product.description }
        </ReactMarkdown>
    )
}

export default React.memo(ProductPageDescription);
