import React, {
    lazy,
    Suspense
} from "react";
import {
    ErrorBoundary
} from "@sentry/react";

import CourseLoadingState from "./components/CourseLoadingState";
import CourseErrorState from "./components/CourseErrorState";

const AccountCourseDetail = lazy(() => import("./AccountCourseDetail"));

function AccountCourseDetailWrapper(props) {
    return (
        <ErrorBoundary
            fallback={
                <CourseErrorState/>
            }
        >
            <Suspense
                fallback={
                    <CourseLoadingState/>
                }
            >
                <AccountCourseDetail {...props }/>
            </Suspense>
        </ErrorBoundary>
    );
}

export default React.memo(AccountCourseDetailWrapper);
