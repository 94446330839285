import React, {
    useContext,
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";

import AuthenticationContext from "../../context/internal/AuthenticationManager";
import OrderDetailCourses from "./OrderDetailCourses";
import DigitalProductLinksList from "./DigitalProductLinksList";
import OrderDigitalProductLinkCard from "./OrderDigitalProductLinkCard";

function OrderDetailProductContentList({ order }) {
    const authentication = useContext(AuthenticationContext);
    const hasSomethingToShow = useMemo(() => {
        return order.products.some((product) => {
            if(product.type?.value === "course") {
                return true;
            }
            return product.type?.value === "digital" && product.productLinks && product.productLinks.length > 0;
        });
    }, [order]);
    if(!hasSomethingToShow) {
        return null;
    }
    return (
        <React.Fragment>
            <h4 className="mt-3">
                <Trans i18nKey="contents"/>
            </h4>
            { order.products.map((product) => {
                if(product.type?.value !== "course") {
                    return null;
                }
                return (
                    <OrderDetailCourses
                        key={ product.id }
                        courses={ product.courses }
                    />
                )
            })}
            { order.products.map((product) => {
                if(product.type?.value !== "digital" || !product.productLinks || product.productLinks.length === 0) {
                    return null;
                }
                if(!authentication.user) {
                    return (
                        <React.Fragment key={ product.id }>
                            <h4 className="mt-4">
                                { product.name }
                            </h4>
                            <DigitalProductLinksList
                                links={ product.productLinks }
                            />
                        </React.Fragment>
                    )
                }
                return (
                    <OrderDigitalProductLinkCard
                        key={ product.id }
                        product={ product }
                    />
                )
            })}
        </React.Fragment>
    )
}

export default React.memo(OrderDetailProductContentList);
