import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Skeleton from "react-loading-skeleton";
import ShopContext from "../../context/internal/ShopManager";

function ProductAvailabilityCheckmark() {
    const product = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!product.product) {
        return (
            <p className="mt-3">
                <Skeleton/>
            </p>
        );
    }
    if(product.product.preorder || !shopContext.shop || !product.product.orderable || !shopContext.shop.orderingEnabled) {
        return null;
    }
    return (
        <p className="text-success mt-3">
            <i className="fas fa-check-circle mr-2"/>
            { product.product.digital ? (
                <b>Direct beschikbaar</b>
            ) : (
                <b>Vandaag besteld, morgen in huis</b>
            )}
        </p>
    );
}

export default React.memo(ProductAvailabilityCheckmark);
