import React from "react";

import Loading from "../../../../../components/Loading";

function CourseLoadingState() {
    return (
        <div className="shopcrate-course-fullscreen">
            <div className="d-flex w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <div>
                    <Loading/>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CourseLoadingState);
