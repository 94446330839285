import React from "react";
import {
    Trans
} from "react-i18next";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";

import VideoPlayer from "../VideoPlayer";

function DigitalProductLinkCard({ link }) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    { link.title }
                </h5>
                <ReactMarkdown>
                    { link.description }
                </ReactMarkdown>
                { link.url && link.url.length > 0 && (
                    <React.Fragment>
                        { ReactPlayer.canPlay(link.url) ? (
                            <VideoPlayer
                                url={ link.url }
                            />
                        ) : (
                            <a href={ link.url } className="btn btn-primary" target="_blank" rel="noreferrer noopener">
                                <Trans i18nKey="openLink"/>
                            </a>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default React.memo(DigitalProductLinkCard);
