import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Trans
} from "react-i18next";

function OrderDetailCourses({ courses }) {
    return courses.map((course) => (
        <div className="card mb-2" key={ course.id }>
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row align-items-md-center">
                    <div className="d-flex flex-column flex-grow-1 mb-2 mb-md-0">
                        <div className="d-flex flex-row mb-2">
                            <div className="mr-2">
                                <h5 className="card-title mb-0">
                                    <i className="fas fa-graduation-cap"/>
                                </h5>
                            </div>
                            <div className="flex-grow-1">
                                <h5 className="card-title mb-0">
                                    { course.title }
                                </h5>
                            </div>
                        </div>
                        <div>
                            <p className="card-text">
                                <Trans i18nKey="courseAccountExplanation"/>
                            </p>
                        </div>
                    </div>
                    <div>
                        <Link to={ "/account/course/" + course.id } className="btn btn-primary btn-sm">
                            <Trans i18nKey="openCourse"/>
                            <i className="fas fa-angle-right ml-2"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    ));
}

export default React.memo(OrderDetailCourses);
