import React, {
    useEffect,
    useState
} from "react";
import {
    DayPickerRangeController
} from "react-dates";

import useWindowSize from "../hooks/WindowSize";

function RentalDateRangePicker(props) {
    const windowSize = useWindowSize();
    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        if(windowSize.width < 768) {
            setNumberOfMonths(1);
        } else if(windowSize.width < 992) {
            setNumberOfMonths(2);
        } else {
            setNumberOfMonths(3);
        }
    }, [windowSize]);

    return (
        <DayPickerRangeController
            numberOfMonths={ numberOfMonths }
            { ...props }
        />
    )
}

export default React.memo(RentalDateRangePicker);
