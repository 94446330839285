import React from "react";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";
import i18next from "i18next";

function CourseErrorState({ error = i18next.t("errorGeneral") }) {
    return (
        <div className="shopcrate-course-fullscreen">
            <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <h1 className="text-danger">
                    <i className="fas fa-exclamation-circle"/>
                </h1>
                <h3>
                    <Trans i18nKey="error"/>
                </h3>
                <p className="mb-3">
                    { error }
                </p>
                <Link to="/account/courses" className="btn btn-primary">
                    Terug naar cursussen
                </Link>
            </div>
        </div>
    )
}

export default React.memo(CourseErrorState);
