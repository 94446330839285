import React from "react";

import Helmet from "../components/Helmet";

function DefaultAccountLayout({ children, title }) {
    return (
        <React.Fragment>
            <Helmet title={ title }/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>{ title }</h1>
                </div>
            </div>
            <div className="container">
                { children }
            </div>
        </React.Fragment>
    )
}

export default React.memo(DefaultAccountLayout);
