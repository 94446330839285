import React, {
    useContext,
    useMemo
} from "react";
import * as moment from "moment-timezone";
import i18next from "i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";

function ProductEndDate() {
    const productContext = useContext(ProductPageContext);

    const localDate = useMemo(() => {
        if(!productContext.product || !productContext.product.endDate) {
            return null;
        }
        const date = moment.tz(productContext.product.endDate, "Europe/Amsterdam");
        if(date < moment.tz()) {
            return null;
        }
        return date.clone().tz(moment.tz.guess());
    }, [productContext.product]);

    const dateString = useMemo(() => {
        if(!localDate) {
            return null;
        }
        const calendar = localDate.calendar({
            sameDay: `[${i18next.t("today")}] HH:mm`,
            nextDay: `[${i18next.t("tomorrow")}] HH:mm`,
            nextWeek: "dddd HH:mm",
            lastDay: `[${i18next.t("yesterday")}] HH:mm`,
            lastWeek: `[${i18next.t("lastCalendarStringPrefix")}] dddd HH:mm`,
            sameElse: "MMM[.] Do HH:mm"
        });
        const timezone = localDate.format("zz");

        return i18next.t("orderableUntil") + " " + calendar + " " + timezone;
    }, [localDate]);

    if(!dateString) {
        return null;
    }
    return dateString;
}

export default React.memo(ProductEndDate);
