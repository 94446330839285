import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";

import CartContext from "../../context/internal/CartManager";
import OrderContext from "../../context/internal/OrderManager";
import ShopContext from "../../context/internal/ShopManager";
import AuthenticationContext from "../../context/internal/AuthenticationManager";
import PaymentMethodContext from "../../context/internal/PaymentMethodManager";
import Loading from "../../components/Loading";
import CartCardSmall from "../../components/cart/CartCardSmall";
import CartPriceSummary from "../../components/cart/CartPriceSummary";
import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import DeliveryInfoAccountCard from "./components/DeliveryInfoAccountCard";

function OrderOverviewPageContent() {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);
    const shop = useContext(ShopContext);
    const authentication = useContext(AuthenticationContext);
    const paymentMethodContext = useContext(PaymentMethodContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const placeOrder = useMemo(() => {
        return async () => {
            setLoading(true);
            setError(null);
            const result = await order.placeOrder();
            if(!result.success) {
                setError(result.errorMessage);
                setLoading(false);
            }
        }
    }, [order]);

    useEffect(() => {
        if(paymentMethodContext.paymentMethods === null) {
            paymentMethodContext.getPaymentMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!shop.shop || !shop.shop.shippingCosts) {
            return;
        }
        if(order.countries === null) {
            order.getCountries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop.shop]);

    const authenticationStatus = authentication.getAuthenticationStatus();
    if(cart.cart === undefined || shop.shop === null || authenticationStatus === "unknown" || paymentMethodContext.paymentMethods === null || order.isLoading()) {
        return (
            <Loading/>
        );
    }
    if(cart.cart === null || cart.cart.products.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }
    if((cart.cart.requiresAccount || shop.shop.authenticationRequiredOrders) && authenticationStatus === "nosession") {
        return (
            <Redirect to="/order/account-selection"/>
        );
    }
    if(!order.isDeliveryInfoValid()) {
        return (
            <Redirect to={ "/order/data" }/>
        );
    }
    if(paymentMethodContext.paymentMethods && !paymentMethodContext.isPaymentMethodValid()) {
        return (
            <Redirect to="/order/paymentmethod"/>
        );
    }

    const paymentMethod = !paymentMethodContext.paymentMethods ? null : paymentMethodContext.paymentMethods.find((paymentMethod) => {
        return paymentMethod.id === paymentMethodContext.paymentMethod;
    });

    const deliveryCountry = !order.countries ? null : order.countries.find((country) => {
        return country.iso === order.deliveryInfo.deliveryCountry;
    });
    const invoiceCountry = !order.countries ? null : order.countries.find((country) => {
        return country.iso === order.deliveryInfo.invoiceCountry;
    });

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="cart"/> } to="/cart" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to="/order/data" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to="/order/paymentmethod" complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to="/order/overview" active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { paymentMethodContext.paymentMethodsError ? (
                <Alert variant="danger">{ paymentMethodContext.paymentMethodsError }</Alert>
            ) : order.countriesError ? (
                <Alert variant="danger">{ order.countriesError }</Alert>
            ) : loading ? (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4>
                            <Trans i18nKey="redirectPayment"/>
                        </h4>
                        <Loading/>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <DeliveryInfoAccountCard/>
                    <div className="row">
                        <div className="col-lg-6 col-xl-7">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="contactInformation"/>
                                        <Link to="/order/data" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { order.deliveryInfo.email }
                                        <br/>
                                        { order.deliveryInfo.phoneNumber }
                                    </p>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        { order.deliveryInfo.invoiceEqualsDelivery ? (
                                            <Trans i18nKey="deliveryInvoiceAddress"/>
                                        ) : (
                                            <Trans i18nKey="deliveryAddress"/>
                                        )}
                                        <Link to="/order/data" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { order.deliveryInfo.deliveryFirstName + " " + order.deliveryInfo.deliveryLastName }
                                        <br/>
                                        { order.deliveryInfo.deliveryStreet + " " + order.deliveryInfo.deliveryHouseNumber }
                                        <br/>
                                        { order.deliveryInfo.deliveryPostalCode + ", " + order.deliveryInfo.deliveryCity }
                                        { shop.shop.shippingCosts && (
                                            <React.Fragment>
                                                <br/>
                                                { order.deliveryInfo.deliveryState + ", " + (deliveryCountry ? deliveryCountry.nameEnglish : "Invalid country") }
                                            </React.Fragment>
                                        )}
                                    </p>
                                </div>
                            </div>
                            { !order.deliveryInfo.invoiceEqualsDelivery && (
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <Trans i18nKey="invoiceAddress"/>
                                            <Link to="/order/data" className="btn btn-link btn-sm">
                                                <Trans i18nKey="edit"/>
                                            </Link>
                                        </h5>
                                        <p className="card-text">
                                            { order.deliveryInfo.invoiceFirstName + " " + order.deliveryInfo.invoiceLastName }
                                            <br/>
                                            { order.deliveryInfo.invoiceStreet + " " + order.deliveryInfo.invoiceHouseNumber }
                                            <br/>
                                            { order.deliveryInfo.invoicePostalCode + ", " + order.deliveryInfo.invoiceCity }
                                            { shop.shop.shippingCosts && (
                                                <React.Fragment>
                                                    <br/>
                                                    { order.deliveryInfo.invoiceState + ", " + (invoiceCountry ? invoiceCountry.nameEnglish : "Invalid country") }
                                                </React.Fragment>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="paymentMethod"/>
                                        <Link to="/order/paymentmethod" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <div className="d-flex">
                                        <img src={ paymentMethod.image.svg } height="24px" alt="Icon"/>
                                        <span className="d-inline ml-2">
                                            { paymentMethod.description }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-5">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="cart"/>
                                        <Link to="/cart" className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    { cart.cart.products.map((product) => (
                                        <CartCardSmall
                                            key={ product.id }
                                            product={ product }
                                        />
                                    ))}
                                    <CartPriceSummary/>
                                </div>
                            </div>
                        </div>
                    </div>
                    { shop.shop && shop.shop.mailingListEnabled && (
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="custom-control custom-checkbox mb-0">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="mailingListSignup"
                                        checked={ order.deliveryInfo.newsletterSignup }
                                        onChange={ (event) => order.updateDeliveryInfo({ newsletterSignup: event.target.checked }) }
                                    />
                                    <label className="custom-control-label" htmlFor="mailingListSignup">
                                        <Trans i18nKey="mailingListSignup"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="card mb-3">
                        <div className="card-body">
                            <Link to="/order/paymentmethod" className="btn btn-link">
                                <i className="fas fa-chevron-left mr-2"/>
                                <Trans i18nKey="previousStep"/>
                            </Link>
                            <div className="float-right">
                                <button className="btn btn-success" onClick={ placeOrder }>
                                    <Trans i18nKey="pay"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(OrderOverviewPageContent);
