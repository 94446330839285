import React, {
    useContext,
    useMemo
} from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";
import i18next from "i18next";

import ShopContext from "../context/internal/ShopManager";

function Helmet({ title = null }) {
    const shopContext = useContext(ShopContext);
    const siteName = useMemo(() => {
        if(!shopContext.shop) {
            return null;
        }
        return shopContext.shop.name;
    }, [shopContext]);
    const pageTitle = useMemo(() => {
        const titleComponents = [];
        if(title) {
            titleComponents.push(title);
        }
        if(siteName) {
            titleComponents.push(siteName);
        }
        if(titleComponents.length === 0) {
            return i18next.t("loading") + "...";
        }
        return titleComponents.join(" - ");
    }, [siteName, title]);
    return (
        <ReactHelmet>
            <title>
                { pageTitle }
            </title>
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
