import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Trans
} from "react-i18next";

import ProductImage from "../../../../../components/ProductImage";
import DateFormatter from "../../../../../components/DateFormatter";

function OrderCard({ order }) {
    return (
        <div className="card order-card mb-3">
            <div className="card-body">
                <div className="d-flex w-100">
                    <div className="flex-grow-1">
                        <h5 className="card-title">
                            <Trans i18nKey="order"/> #{ order.id }
                        </h5>
                    </div>
                    <div className="text-muted">
                        <DateFormatter date={ order.orderDate }/>
                    </div>
                </div>
                <div className="order-card-products-container mb-3">
                    <div className="order-card-products-scroll">
                        { order.products.map((product) => (
                            <div className="order-card-product card" key={ product.id }>
                                <div
                                    className="d-flex justify-content-center align-items-center rounded mx-3 mt-3"
                                    style={{
                                        width: "175px",
                                        height: "130px",
                                        backgroundColor: "rgba(0, 0, 0, 0.05)"
                                    }}
                                >
                                    <ProductImage
                                        size="175x130"
                                        image={ product.images[0] }
                                        alt={ product.name }
                                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                                    />
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title mb-0">
                                        { product.name }
                                    </h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Link
                    to={`/account/order/${order.id}`}
                    className="btn btn-link btn-sm px-0"
                >
                    <Trans i18nKey="showOrder"/>
                    <i className="fas fa-angle-right ml-2"/>
                </Link>
            </div>
        </div>
    )
}

export default React.memo(OrderCard);
