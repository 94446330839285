import React, {
    useContext
} from "react";
import i18next from "i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductPageStock() {
    const product = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!product.product || !shopContext.shop) {
        return null;
    }
    if(!shopContext.shop.orderingEnabled) {
        return null;
    }
    if(product.product.stock === null || product.product.stock <= 0) {
        return null;
    }
    return i18next.t("stockNotice").replace("{amount}", product.product.stock);
}

export default React.memo(ProductPageStock);
