import React from "react";

import Helmet from "../components/Helmet";
import Page404 from "./DefaultPage404";
import DefaultCategoryPage from "../page-content/category/DefaultCategoryPage";

function DefaultCategoryLayout() {
    return (
        <DefaultCategoryPage
            error404Page={ <Page404/> }
            helmet={ (categoryContext) => (
                <Helmet title={ categoryContext.category ? categoryContext.category.name : null }/>
            )}
        />
    )
}

export default React.memo(DefaultCategoryLayout);
