import React, {
    useContext,
    useRef,
    useState
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar,
    NavDropdown
} from "react-bootstrap";
import {
    AuthenticationContext,
    CartProductCountBadge,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import useOutsideAlerter from "../hooks/OutsideAlerter";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    const authentication = useContext(AuthenticationContext);
    const shop = useContext(ShopContext);

    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }

    let [showCartDropdown, setShowCartDropdown] = useState(false);
    let toggleCartDropdown = () => setShowCartDropdown(!showCartDropdown);

    let matchCart = useRouteMatch({
        path: "/cart",
        exact: false
    });

    const cartDropdownRef = useRef(null);
    useOutsideAlerter(cartDropdownRef, () => setShowCartDropdown(false));

    return (
        <React.Fragment>
            <RBNavbar bg="light" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand" href="/">
                        ShopCrate Demo Shop
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" exact onClick={ collapse }/>
                            <NavbarLink to="/category/2" title="Demo Categorie" onClick={ collapse }/>

                            { shop.shop && shop.shop.orderingEnabled && (
                                <NavDropdown
                                    ref={ cartDropdownRef }
                                    id="cart-dropdown"
                                    active={ matchCart !== null }
                                    show={ showCartDropdown }
                                    onClick={ toggleCartDropdown }
                                    title={
                                        <React.Fragment>
                                            <i className="fas fa-shopping-cart"/>
                                            <CartProductCountBadge className="ml-2"/>
                                        </React.Fragment>
                                    }
                                    alignRight
                                >
                                    <Link to="/cart" className="dropdown-item">Winkelwagen bekijken</Link>
                                </NavDropdown>
                            )}

                            { authentication.supported && (
                                <NavbarLink to="/account" title={ <i className="fas fa-user fa-fw"/> } onClick={ collapse }/>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div style={{ height: "56px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
