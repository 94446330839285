import React, {
    createContext
} from "react";
import {
    withRouter
} from "react-router-dom";
import i18next from "i18next";

import {
    withShopContext
} from "./ShopManager";
import {
    SHOPCRATE_LANGUAGE
} from "../../ShopCrateLocalization";

const AuthenticationContext = createContext(null);

class AuthenticationManagerInternal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            supported: undefined,
            user: undefined,
            loading: false,
            logoutError: null,
            login: this.login.bind(this),
            register: this.register.bind(this),
            forgotPassword: this.forgotPassword.bind(this),
            logout: this.logout.bind(this),
            getAuthenticationStatus: this.getAuthenticationStatus.bind(this)
        }
    }

    componentDidMount() {
        if(this.state.supported) {
            this.getSession();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.shopContext.shop !== this.props.shopContext.shop) {
            this.updateAuthenticationSupported();
        }
        if(!prevState.supported && this.state.supported) {
            this.getSession();
        }
    }

    updateAuthenticationSupported() {
        const {
            shop,
            shopApi
        } = this.props.shopContext;
        if(!shop) {
            this.setState({ supported: undefined });
            return;
        }
        if(shop.authenticationSupported && !shopApi) {
            this.setState({ supported: undefined });
            return;
        }
        this.setState({
            supported: !!shop.authenticationSupported
        });
    }

    getAuthenticationStatus() {
        const {
            user,
            supported
        } = this.state;
        if(supported === false) {
            return "unsupported";
        }
        switch(user) {
        case undefined: return "unknown";
        case null: return "nosession";
        default: return "loggedin";
        }
    }

    getSession() {
        const {
            shopApi
        } = this.props.shopContext;
        this.setState({ loading: true });
        shopApi.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                } else {
                    this.setState({ user: null });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ user: null });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    login(email, password, onError, urlAfterLogin = "/account") {
        const {
            shopApi
        } = this.props.shopContext;
        this.setState({ loading: true });
        shopApi.post("/login", { email, password, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                    this.props.history.push(urlAfterLogin);
                } else {
                    const error = response.data.error;
                    switch(error) {
                    case "INVALID_CREDENTIALS":
                        onError(i18next.t("errorInvalidCredentials"));
                        break;
                    default:
                        onError(i18next.t("errorGeneral") + " (" + error + ")");
                        break;
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                onError(i18next.t("errorGeneral"));
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    register(name, email, password, passwordConfirm, onError, urlAfterRegister = "/account") {
        const {
            shopApi
        } = this.props.shopContext;
        this.setState({ loading: true });
        shopApi.post("/register", { name, email, password, passwordConfirm, useCookie: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: response.data.user });
                    this.props.history.push(urlAfterRegister);
                } else {
                    const error = response.data.error;
                    switch(error) {
                    case "INVALID_PARAMETERS":
                        onError(i18next.t("errorInvalidParameters"));
                        break;
                    case "INVALID_EMAIL":
                        onError(i18next.t("errorInvalidEmail"));
                        break;
                    case "PASSWORD_MISMATCH":
                        onError(i18next.t("errorPasswordMismatch"));
                        break;
                    case "PASSWORD_INSECURE":
                        onError(i18next.t("errorPasswordInsecure"));
                        break;
                    case "EMAIL_IN_USE":
                        onError(i18next.t("errorEmailInUse"));
                        break;
                    default:
                        onError(i18next.t("errorGeneral") + " (" + error + ")");
                        break;
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                onError(i18next.t("errorGeneral"));
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    forgotPassword(email, onError, onSuccess) {
        const {
            shopApi
        } = this.props.shopContext;
        this.setState({ loading: true });
        shopApi.post("/forgotPassword", { email, language: SHOPCRATE_LANGUAGE })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess();
                } else {
                    onError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError(i18next.t("errorGeneral"));
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    logout() {
        const {
            shopApi
        } = this.props.shopContext;
        this.setState({ loading: true });
        shopApi.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ user: null });
                } else {
                    this.setState({ logoutError: i18next.t("errorGeneral") + " (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ logoutError: i18next.t("errorGeneral") });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <AuthenticationContext.Provider value={ this.state }>
                { this.props.children }
            </AuthenticationContext.Provider>
        );
    }

}

export function withAuthenticationContext(Component) {
    return function contextComponent(props) {
        return (
            <AuthenticationContext.Consumer>
                {context => <Component {...props} authenticationContext={context} />}
            </AuthenticationContext.Consumer>
        )
    }
}

export const AuthenticationManager = withRouter(withShopContext(AuthenticationManagerInternal));

export default AuthenticationContext;
