import React, {
    useContext,
    useMemo
} from "react";
import {
    Link,
    Redirect
} from "react-router-dom";
import {
    Trans
} from "react-i18next";

import CartContext from "../../context/internal/CartManager";
import AuthenticationContext from "../../context/internal/AuthenticationManager";
import ShopContext from "../../context/internal/ShopManager";
import Loading from "../../components/Loading";

function AccountSelectionPageContent() {
    const cart = useContext(CartContext);
    const shop = useContext(ShopContext);
    const authentication = useContext(AuthenticationContext);

    const authenticationStatus = useMemo(() => {
        return authentication.getAuthenticationStatus();
    }, [authentication]);

    const accountRequiredProducts = useMemo(() => {
        if(!cart.cart || !cart.cart.requiresAccount) {
            return [];
        }
        return cart.cart.products.filter((product) => {
            return product.type.requiresAccount;
        });
    }, [cart]);

    if(cart.cart === undefined || shop.shop === null || authenticationStatus === "unknown") {
        return (
            <Loading/>
        );
    }
    if(cart.cart === null || cart.cart.products.length <= 0) {
        return (
            <Redirect to="/cart"/>
        );
    }
    if(authenticationStatus === "unsupported" || authenticationStatus === "loggedin") {
        return (
            <Redirect to="/order/data"/>
        )
    }

    return (
        <React.Fragment>
            <div className="row text-center justify-content-center">
                { !shop.shop.authenticationRequiredOrders && !cart.cart.requiresAccount && (
                    <div className="col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    <Trans i18nKey="orderWithoutAccount"/>
                                </h4>
                                <Link to="/order/data" className="btn btn-secondary px-4">
                                    <Trans i18nKey="continue"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-md-6 mb-2">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">
                                <Trans i18nKey="orderWithAccount"/>
                            </h4>
                            <div className="d-flex w-100 justify-content-center">
                                <Link to="/order/login" className="btn btn-success px-4 mx-2">
                                    <Trans i18nKey="login"/>
                                </Link>
                                <Link to="/order/register" className="btn btn-secondary px-4 mx-2">
                                    <Trans i18nKey="register"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { !shop.shop.authenticationRequiredOrders && cart.cart.requiresAccount && (
                <div className="row text-center justify-content-center">
                    <div className="col-md-6 mb-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    <Trans i18nKey="accountSelectionAccountRequired"/>
                                </h4>
                                <p className="card-text">
                                    <Trans i18nKey="accountSelectionAccountRequiredDescription"/>
                                </p>
                                { accountRequiredProducts.map((product) => (
                                    <p className="mb-0" key={ product.id }>
                                        { product.name }
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(AccountSelectionPageContent);
