import React from "react";
import {
    Trans
} from "react-i18next";

function AccountEmptyStateCard({ icon = "fad fa-store", title, description, description2 }) {
    return (
        <div className="card">
            <div className="card-body text-center">
                <h1 className="text-primary">
                    <i className={ icon }/>
                </h1>
                <h3>
                    <Trans i18nKey={ title }/>
                </h3>
                <p className="card-text text-center">
                    <Trans i18nKey={ description }/>
                    { description2 && (
                        <React.Fragment>
                            <br className="d-none d-sm-block"/>
                            <span className="d-sm-none"> </span>
                            <Trans i18nKey="noOrdersYetDescription2"/>
                        </React.Fragment>
                    )}
                </p>
            </div>
        </div>
    )
}

export default React.memo(AccountEmptyStateCard);
