import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import Loading from "../../../../components/Loading";
import ShopContext from "../../../../context/internal/ShopManager";
import DigitalProductLinksList from "../../../../components/order/DigitalProductLinksList";

function AccountDigitalProductDetail({ match }) {
    const shopContext = useContext(ShopContext);
    const [product, setProduct] = useState(null);
    const [links, setLinks] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        const productId = parseInt(match.params.productId);
        if(!productId || !shopApi) {
            return;
        }
        setProduct(null);
        setError(null);
        shopApi.post("/getDigitalProduct", { productId })
            .then((response) => {
                if(response.data.valid) {
                    setProduct(response.data.product);
                    setLinks(response.data.links);
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            })
    }, [shopContext, match.params.orderId]);

    return (
        <React.Fragment>
            <Link to="/account/digital-products" className="btn btn-link px-0">
                <i className="fas fa-angle-left mr-2"/>
                <Trans i18nKey="backToDigitalProductsOverview"/>
            </Link>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !product ? (
                <Loading/>
            ) : (
                <React.Fragment>
                    <h3>
                        { product.name }
                    </h3>
                    <DigitalProductLinksList links={ links }/>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(React.memo(AccountDigitalProductDetail));
