import React from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";

import useWindowSize from "../../../../../hooks/WindowSize";

function SidebarLesson({ lesson, course, onClick, baseUrl }) {
    const url = `${ baseUrl }/lesson/${ lesson.id }`;
    const match = useRouteMatch({
        path: url,
        exact: true
    });
    return (
        <Link
            to={ url }
            className={ "chapter-lesson" + (match ? " active" : "") }
            onClick={ onClick }
        >
            <div className="lesson-title">
                { lesson.title }
            </div>
            { lesson.completed && (
                <div className="lesson-completion-icon">
                    <i className="fas fa-check-circle ml-2"/>
                </div>
            )}
        </Link>
    )
}

function CourseSidebar({ course, mobile, onClose, headerHeight, baseUrl }) {
    const windowSize = useWindowSize();
    return (
        <div
            className="course-sidebar"
            style={{
                height: mobile ? windowSize.height : windowSize.height - headerHeight
            }}
        >
            { mobile && (
                <div className="course-sidebar-header">
                    <div className="course-title">
                        { course.title }
                    </div>
                    <div>
                        <Button variant="link" size="sm" onClick={ onClose }>
                            <i className="fas fa-angle-left fa-fw mr-2"/>
                            Sluiten
                        </Button>
                    </div>
                </div>
            )}
            { course.chapters.map((chapter) => (
                <div className="course-sidebar-chapter" key={ chapter.id }>
                    <div className="chapter-title">
                        { chapter.title }
                    </div>
                    <div className="chapter-lessons">
                        { chapter.lessons.map((lesson) => (
                            <SidebarLesson
                                key={ lesson.id }
                                lesson={ lesson }
                                course={ course }
                                onClick={ onClose }
                                baseUrl={ baseUrl }
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default React.memo(CourseSidebar);
