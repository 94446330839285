import React, {
    createContext
} from "react";
import {
    Redirect,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import i18next from "i18next";

import shopcrateApi from "../ShopCrateAPI";

export const CategoryPageContext = createContext(null);

class CategoryPageManager extends React.PureComponent {
    state = {
        context: {
            category: null,
            error: null,
            errorCode: null
        }
    }

    setContextState(state) {
        this.setState(previousState => {
            return { context: { ...previousState.context, ...state } }
        });
    }

    componentDidMount() {
        if(this.props.categoryId) {
            this.getCategory(this.props.categoryId);
        } else {
            this.getCategory(this.props.match.params.categoryId);
        }
    }

    getCategory(categoryId) {
        this.setContextState({ category: null, error: null });
        shopcrateApi.post("/getCategory", { categoryId })
            .then((response) => {
                if(response.data.valid) {
                    this.setContextState({ category: response.data.category });
                } else {
                    this.setContextState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setContextState({ error: i18next.t("errorGeneral") });
            })
    }

    render() {
        if(!this.props.categoryId && this.state.context.category && this.state.context.category.url !== this.props.match.params.categoryUrl) {
            return (
                <Redirect to={ "/category/" + this.state.context.category.id + "/" + this.state.context.category.url }/>
            )
        }
        return (
            <CategoryPageContext.Provider value={ this.state.context }>
                { this.state.context.error ? (
                    <React.Fragment>
                        { this.props.error404Page && (this.state.context.errorCode === "DOESNT_EXIST" || this.state.context.errorCode === "INVALID_PARAMETERS") ? (
                            <React.Fragment>
                                { this.props.error404Page }
                            </React.Fragment>
                        ) : (
                            <div className="container mt-5">
                                <Alert variant="danger">{ this.state.context.error }</Alert>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { this.props.children }
                    </React.Fragment>
                )}
            </CategoryPageContext.Provider>
        )
    }
}

export default withRouter(CategoryPageManager);
