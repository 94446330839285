import React, {
    createContext
} from "react";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import i18next from "i18next";

import shopcrateApi from "../ShopCrateAPI";
import AddedToCardModal from "../components/cart/AddedToCardModal";
import Countdown from "../components/Countdown";
import {
    withShopContext
} from "./internal/ShopManager";

export const ProductPageContext = createContext(null);

class ProductPageManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                error: null,
                errorCode: null,
                releaseDate: null,
                product: null,
                type: props.rental ? "rental" : "product",

                showAddedToCartModal: false,
                triggerAddedToCardModal: this.showAddedToCartModal.bind(this),

                getFormattedPrice: this.getFormattedPrice.bind(this),
                getFormattedDiscountPrice: this.getFormattedDiscountPrice.bind(this)
            }
        }
        this.handleAddedToCardClose = this.handleAddedToCardClose.bind(this);
    }

    setContextState(state) {
        this.setState(previousState => {
            return { context: { ...previousState.context, ...state } }
        });
    }

    componentDidMount() {
        if(this.props.productId) {
            this.getProduct(this.props.productId);
        } else {
            this.getProduct(this.props.match.params.productId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.productId) {
            if(this.props.productId !== prevProps.productId) {
                this.getProduct(this.props.productId);
            }
        } else {
            if(this.props.match.params.productId !== prevProps.match.params.productId) {
                this.getProduct(this.props.match.params.productId);
            }
        }
    }

    getProduct(productId) {
        this.setContextState({ product: null, error: null });
        shopcrateApi.post("/getProduct", { productId })
            .then((response) => {
                if(response.data.valid) {
                    const product = response.data.product;
                    if((this.state.context.type === "rental") !== product.rental) {
                        this.setContextState({ error: i18next.t("errorGeneral") + " (DOESNT_EXIST)", errorCode: "DOESNT_EXIST" });
                    } else {
                        this.setContextState({ product });
                        if(!this.props.productId && product.url !== this.props.match.params.productUrl) {
                            this.props.history.replace("/" + this.state.context.type + "/" + product.id + "/" + product.url);
                        }
                    }
                } else {
                    this.setContextState({ error: i18next.t("errorGeneral") + " (" + response.data.error + ")", errorCode: response.data.error });
                    if(response.data.error === "NOT_RELEASED_YET") {
                        this.setContextState({ releaseDate: response.data.date });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setContextState({ error: i18next.t("errorGeneral") });
            })
    }

    getFormattedPrice() {
        const product = this.state.context.product;
        if(!product) {
            return null;
        }
        return this.formatPrice(product.price);
    }

    getFormattedDiscountPrice() {
        const product = this.state.context.product;
        if(!product || product.discountPrice === null) {
            return null;
        }
        return this.formatPrice(product.discountPrice);
    }

    formatPrice(price) {
        let wholeNumber = Math.floor(price);
        let cents = Math.round((price - wholeNumber) * 100);
        if(cents > 0 && cents < 10) {
            return {
                price: wholeNumber,
                cents: `0${cents}`
            }
        } else if(cents !== 0) {
            return {
                price: wholeNumber,
                cents: cents
            }
        } else {
            return {
                price: wholeNumber,
                cents: "-"
            }
        }
    }

    showAddedToCartModal() {
        this.setContextState({ showAddedToCartModal: true });
    }

    handleAddedToCardClose() {
        this.setContextState({ showAddedToCartModal: false });
    }

    render() {
        const {
            shopContext,
            children,
            error404Page
        } = this.props;
        const {
            context
        } = this.state;
        return (
            <ProductPageContext.Provider value={ context }>
                { context.error ? (
                    <React.Fragment>
                        { error404Page && (context.errorCode === "DOESNT_EXIST" || context.errorCode === "INVALID_PARAMETERS") ? (
                            <React.Fragment>
                                { error404Page }
                            </React.Fragment>
                        ) : context.releaseDate && (context.errorCode === "NOT_RELEASED_YET") ? (
                            <div className="container my-5 text-center d-flex flex-grow-1 align-items-center">
                                <div className="d-flex w-100 h-100 align-items-center" style={{ minHeight: "380px" }}>
                                    <h1 className="text-white w-100">
                                        <Countdown
                                            date={ context.releaseDate }
                                        />
                                    </h1>
                                </div>
                            </div>
                        ) : (
                            <div className="container mt-5">
                                <Alert variant="danger">{ context.error }</Alert>
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { children }
                        { context.product && shopContext.shop && shopContext.shop.orderingEnabled && (
                            <AddedToCardModal
                                show={ context.showAddedToCartModal }
                                handleClose={ this.handleAddedToCardClose }
                                product={ context.product }
                            />
                        )}
                    </React.Fragment>
                )}
            </ProductPageContext.Provider>
        )
    }
}

export default withRouter(withShopContext(ProductPageManager));
