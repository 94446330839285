import React, {
    useContext,
    useMemo
} from "react";

import CartContext from "../../context/internal/CartManager";

function CartProductCountBadge({ className, ...props }) {
    const cartContext = useContext(CartContext);

    const classNames = useMemo(() => {
        let names = ["badge badge-pill badge-primary"];
        if(className) {
            names.push(className);
        }
        return names.join(" ");
    }, [className]);

    const productCount = useMemo(() => {
        if(cartContext.cart === undefined) {
            return null;
        }
        if(!cartContext.cart) {
            return 0;
        }
        return cartContext.cart.products.reduce((total, product) => {
            return total + product.count;
        }, 0);
    }, [cartContext.cart]);

    if(productCount === null) {
        return null;
    }
    return (
        <span { ...props } className={ classNames }>
            { productCount }
        </span>
    )
}

export default React.memo(CartProductCountBadge);
