import React, {
    useContext
} from "react";
import {
    Trans
} from "react-i18next";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductPagePreorderNotice() {
    const productContext = useContext(ProductPageContext);
    const shopContext = useContext(ShopContext);
    if(!productContext.product || !shopContext.shop) {
        return null;
    }
    if(!shopContext.shop.orderingEnabled) {
        return null;
    }
    if(!productContext.product.preorder || !productContext.product.orderable) {
        return null;
    }
    return (
        <Trans i18nKey="preorder"/>
    )
}

export default React.memo(ProductPagePreorderNotice);
