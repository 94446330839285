import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import i18next from "i18next";
import {
    useHistory,
    useRouteMatch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import Skeleton from "react-loading-skeleton";

import OrderCard from "./components/OrderCard";
import ShopContext from "../../../../context/internal/ShopManager";
import PaginationBar from "../../../../components/PaginationBar";
import AccountEmptyStateCard from "../components/AccountEmptyStateCard";

const pageSize = 10;

function AccountOrders() {
    const shopContext = useContext(ShopContext);
    const [orders, setOrders] = useState(null);
    const [total, setTotal] = useState(null);
    const [error, setError] = useState(null);
    const match = useRouteMatch();
    const history = useHistory();

    const page = useMemo(() => {
        if(!match.params.page) {
            return 1;
        }
        const parsed = parseInt(match.params.page);
        if(!parsed) {
            return 1;
        }
        return parsed;
    }, [match.params.page]);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        if(!shopApi) {
            return;
        }
        setOrders(null);
        shopApi.post("/getOrders", { page, pageSize })
            .then((response) => {
                if(response.data.valid) {
                    setOrders(response.data.orders);
                    setTotal(response.data.total);
                } else {
                    setError(i18next.t("errorGeneral"));
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            });
    }, [shopContext, page]);

    const onPageChange = useMemo(() => {
        return (newPage) => {
            history.push(`/account/orders/${newPage}`)
        }
    }, [history]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    return (
        <React.Fragment>
            <h3>
                <Trans i18nKey="orders"/>
            </h3>
            { total !== null && total > pageSize && (
                <PaginationBar
                    onPageChange={ onPageChange }
                    pagesAmount={ Math.ceil(total / pageSize) }
                    page={ page }
                />
            )}
            { !orders ? (
                [...Array(pageSize)].map((value, index) => (
                    <div key={ index } className="mb-3">
                        <Skeleton height={ 350 }/>
                    </div>
                ))
            ) : orders.length === 0 ? (
                <AccountEmptyStateCard
                    title="noOrdersYet"
                    description="noOrdersYetDescription1"
                    description2="noOrdersYetDescription2"
                />
            ) : orders.map((order) => (
                <OrderCard
                    order={ order }
                    key={ order.id }
                />
            ))}
            { total !== null && total > pageSize && (
                <PaginationBar
                    onPageChange={ onPageChange }
                    pagesAmount={ Math.ceil(total / pageSize) }
                    page={ page }
                />
            )}
        </React.Fragment>
    );
}

export default React.memo(AccountOrders);
