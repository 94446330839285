import React from "react";

import AccountCourseDetailWrapper from "./account/tabs/courses/AccountCourseDetailWrapper";

function CourseDemoPageContent() {
    return (
        <AccountCourseDetailWrapper/>
    );
}

export default React.memo(CourseDemoPageContent);
