import React, {
    useContext,
    useState
} from "react";
import {
    Link,
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import RentalContext from "../../context/internal/RentalManager";

function RentalInfoPageContent(props) {
    const productId = parseInt(props.match.params.productId);

    const rental = useContext(RentalContext);
    const history = useHistory();
    const [error, setError] = useState(null);
    const onError = (newError) => {
        setError(newError);
        window.scroll({ top: 0, behavior: "smooth" });
    }
    const toNextStep = () => {
        if(!rental.isRentalInfoValid()) {
            onError("Niet alle velden zijn correct ingevuld!");
            return;
        }
        history.push("/rental-order/" + productId + "/paymentmethod");
    };
    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="product"/> } to={ "/rental/" + productId } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="date"/> } to={ "/rental-order/" + productId + "/date" } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to={ "/rental-order/" + productId + "/data" } active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to={ "/rental-order/" + productId + "/paymentmethod" }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to={ "/rental-order/" + productId + "/overview" }/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="customer"/>
                    </h4>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" id="private"
                               name="company"
                               checked={ !rental.rentalInfo.company }
                               onChange={(event) => rental.updateRentalInfo({company: !event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="private">
                            <Trans i18nKey="customerPersonal"/>
                        </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" id="company"
                               name="company"
                               checked={ rental.rentalInfo.company }
                               onChange={(event) => rental.updateRentalInfo({company: event.target.checked})}
                        />
                        <label className="custom-control-label" htmlFor="company">
                            <Trans i18nKey="customerCompany"/>
                        </label>
                    </div>
                    { rental.rentalInfo.company && (
                        <div className="form-group">
                            <label htmlFor="companyName">
                                <Trans i18nKey="companyName"/>
                            </label>
                            <input type="text" className="form-control" id="companyName" required
                                   placeholder={ i18next.t("companyName") }
                                   value={rental.rentalInfo.companyName}
                                   onChange={(event) => rental.updateRentalInfo({companyName: event.target.value})}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="invoiceAddress"/>
                    </h4>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="invoiceFirstName">
                                    <Trans i18nKey="firstName"/>
                                </label>
                                <input type="text" className="form-control" id="invoiceFirstName" required
                                       placeholder={ i18next.t("firstName") }
                                       value={rental.rentalInfo.invoiceFirstName}
                                       onChange={(event) => rental.updateRentalInfo({invoiceFirstName: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="invoiceLastName">
                                    <Trans i18nKey="lastName"/>
                                </label>
                                <input type="text" className="form-control" id="invoiceLastName" required
                                       placeholder={ i18next.t("lastName") }
                                       value={rental.rentalInfo.invoiceLastName}
                                       onChange={(event) => rental.updateRentalInfo({invoiceLastName: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="form-group">
                                <label htmlFor="invoiceStreet">
                                    <Trans i18nKey="street"/>
                                </label>
                                <input type="text" className="form-control" id="invoiceStreet" required
                                       placeholder={ i18next.t("street") }
                                       value={rental.rentalInfo.invoiceStreet}
                                       onChange={(event) => rental.updateRentalInfo({invoiceStreet: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="invoiceHouseNumber">
                                    <Trans i18nKey="houseNumber"/>
                                </label>
                                <input type="text" className="form-control" id="invoiceHouseNumber" required
                                       placeholder={ i18next.t("houseNumber") }
                                       value={rental.rentalInfo.invoiceHouseNumber}
                                       onChange={(event) => rental.updateRentalInfo({invoiceHouseNumber: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="invoicePostalCode">
                                    <Trans i18nKey="postalCode"/>
                                </label>
                                <input type="text" className="form-control" id="invoicePostalCode" required
                                       placeholder={ i18next.t("postalCode") }
                                       value={rental.rentalInfo.invoicePostalCode}
                                       onChange={(event) => rental.updateRentalInfo({invoicePostalCode: event.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <label htmlFor="invoiceCity">
                                    <Trans i18nKey="city"/>
                                </label>
                                <input type="text" className="form-control" id="invoiceCity" required
                                       placeholder={ i18next.t("city") }
                                       value={rental.rentalInfo.invoiceCity}
                                       onChange={(event) => rental.updateRentalInfo({invoiceCity: event.target.value})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>
                        <Trans i18nKey="contactInformation"/>
                    </h4>
                    <div className="form-group">
                        <label htmlFor="email">
                            <Trans i18nKey="email"/>
                        </label>
                        <input type="email" className="form-control" id="email" required
                               placeholder={ i18next.t("email") }
                               value={rental.rentalInfo.email}
                               onChange={(event) => rental.updateRentalInfo({email: event.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">
                            <Trans i18nKey="phoneNumber"/>
                        </label>
                        <input type="text" className="form-control" id="phoneNumber" required
                               placeholder="06 12345678"
                               value={rental.rentalInfo.phoneNumber}
                               onChange={(event) => rental.updateRentalInfo({phoneNumber: event.target.value})}
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <Link to="/cart" className="btn btn-link">
                        <i className="fas fa-chevron-left mr-2"/>
                        <Trans i18nKey="previousStep"/>
                    </Link>
                    <div className="float-right">
                        <Button variant="success" onClick={ toNextStep }>
                            <Trans i18nKey="nextStep"/>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(withRouter(RentalInfoPageContent));
