import React from "react";

import Helmet from "../components/Helmet";
import DefaultRentalPage from "../page-content/product/DefaultRentalPage";
import Page404 from "./DefaultPage404";

function DefaultRentalLayout() {
    return (
        <DefaultRentalPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    )
}

export default React.memo(DefaultRentalLayout);
