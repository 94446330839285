import React, {
    useContext,
    useEffect,
    useState
} from "react";
import i18next from "i18next";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import {
    Link
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import ShopContext from "../../../../context/internal/ShopManager";
import AccountEmptyStateCard from "../components/AccountEmptyStateCard";
import AccountCourseCard from "./components/AccountCourseCard";

function AccountCourses() {
    const shopContext = useContext(ShopContext);
    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const {
            shopApi
        } = shopContext;
        if(!shopApi) {
            return;
        }
        setCourses(null);
        shopApi.post("/getCourses")
            .then((response) => {
                if(response.data.valid) {
                    setCourses(response.data.courses);
                } else {
                    setError(i18next.t("errorGeneral"));
                }
            })
            .catch((error) => {
                console.error(error);
                setError(i18next.t("errorGeneral"));
            });
    }, [shopContext]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    return (
        <React.Fragment>
            <h3>
                <Trans i18nKey="courses"/>
            </h3>
            { !courses ? (
                [...Array(10)].map((value, index) => (
                    <div key={ index } className="mb-3">
                        <Skeleton height={ 350 }/>
                    </div>
                ))
            ) : courses.length === 0 ? (
                <AccountEmptyStateCard
                    title="noCoursesYet"
                    description="noCoursesYetDescription1"
                    description2="noCoursesYetDescription2"
                />
            ) : courses.map((course) => (
                <AccountCourseCard
                    key={ course.id }
                    course={ course }
                />
            ))}
        </React.Fragment>
    );
}

export default React.memo(AccountCourses);
