import React, {
    useContext,
    useMemo
} from "react";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../../context/internal/CartManager";
import OrderContext from "../../context/internal/OrderManager";
import PriceFormatter from "../PriceFormatter";

function CartPriceSummaryRow({ title, subtitle, children, className }) {
    return (
        <div className={ "d-flex justify-content-end text-right" + (className ? " " + className : "") }>
            <div style={{ width: "150px" }}>
                <b>
                    { title }
                </b>
                { subtitle && (
                    <React.Fragment>
                        <br/>
                        <span className="text-muted">
                            { subtitle }
                        </span>
                    </React.Fragment>
                )}
            </div>
            <div style={{ width: "150px" }}>
                <b>
                    { children }
                </b>
            </div>
        </div>
    )
}

function CartPriceSummary({ className }) {
    const cart = useContext(CartContext);
    const order = useContext(OrderContext);

    const shippingPrice = useMemo(() => {
        if(!cart.cart.requiresShipping) {
            return 0;
        }
        return order.shippingPrice ?? 0;
    }, [cart, order]);
    const {
        totalVat,
        totalPrice,
        totalDiscount
    } = useMemo(() => {
        return {
            totalVat: cart.cart.totalVat + (shippingPrice - shippingPrice / 1.21),
            totalPrice: cart.cart.totalPriceInclVat + shippingPrice,
            totalDiscount: cart.cart.totalDiscount
        }
    }, [cart, shippingPrice]);

    return (
        <div className={ "card" + (className ? " " + className : "") }>
            <div className="card-body">
                <CartPriceSummaryRow
                    title={ i18next.t("shippingCosts") }
                    className="mb-2"
                >
                    { shippingPrice === null ? (
                        <Trans i18nKey="selectCountry"/>
                    ) : shippingPrice === 0 ? (
                        <Trans i18nKey="free"/>
                    ) : (
                        <PriceFormatter price={ shippingPrice }/>
                    ) }
                </CartPriceSummaryRow>
                <CartPriceSummaryRow
                    title={ i18next.t("vat") }
                    className="mb-2 text-muted"
                >
                    <PriceFormatter price={ totalVat }/>
                </CartPriceSummaryRow>
                { totalDiscount > 0 && (
                    <CartPriceSummaryRow
                        title={ i18next.t("discount") }
                        className="mb-2 text-muted"
                    >
                        <PriceFormatter price={ totalDiscount }/>
                    </CartPriceSummaryRow>
                )}
                <CartPriceSummaryRow
                    title={ i18next.t("totalPrice") }
                    subtitle={ i18next.t("includingVat") }
                >
                    <PriceFormatter price={ totalPrice }/>
                </CartPriceSummaryRow>
            </div>
        </div>
    )
}

export default React.memo(CartPriceSummary);
