import React, {
    useContext
} from "react";
import * as moment from "moment-timezone";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import Countdown from "../Countdown";

function ProductEndDateCountdown() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product || !productContext.product.endDate) {
        return null;
    }
    const date = moment.tz(productContext.product.endDate, "Europe/Amsterdam");
    if(date < moment.tz()) {
        return null;
    }
    return (
        <Countdown
            separateLines
            date={ productContext.product.endDate }
        />
    )
}

export default React.memo(ProductEndDateCountdown);
