import React, {
    useContext,
    useState
} from "react";
import {
    Carousel
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import ProductImage from "../ProductImage";
import ProductImagePlaceholder from "../ProductImagePlaceholder";

function ProductImageCarousel(props) {
    const productContext = useContext(ProductPageContext);

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    }

    if(!productContext.product) {
        return (
            <Skeleton height={ 500 }/>
        )
    }

    if(!productContext.product.images || productContext.product.images.length === 0) {
        return (
            <div className="bg-white" style={{ height: "400px" }}>
                <ProductImagePlaceholder/>
            </div>
        )
    }

    return (
        <React.Fragment>
            <Carousel
                className="rounded-top"
                activeIndex={ index }
                onSelect={ handleSelect }
                prevIcon={
                    <i className="fas fa-angle-left text-primary" style={{ fontSize: "2.5rem" }}/>
                }
                nextIcon={
                    <i className="fas fa-angle-right text-primary" style={{ fontSize: "2.5rem" }}/>
                }
                indicators={ false }
                wrap={ false }
                interval={ null }
            >
                { productContext.product.images.map((image) => (
                    <Carousel.Item key={ image.id }>
                        <div className="d-flex justify-content-center align-content-center rounded" style={{ height: props.height ? props.height : 500 + "px", backgroundColor: "white" }}>
                            <img
                                src={ image.url }
                                className="img-fluid rounded"
                                alt="Product"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="d-block bg-white rounded-bottom pb-2 px-1">
                { productContext.product.images.map((image, imageIndex) => {
                    const selected = imageIndex === index;
                    return (
                        <div
                            key={ imageIndex }
                            className={ "mt-2 d-inline-flex justify-content-center align-content-center rounded mx-1 " + (selected ? "border-primary" : "") }
                            style={{
                                padding: selected ? "3px" : "5px",
                                border: selected ? "3px solid" : "1px solid #CCC",
                                width: "87px",
                                height: "87px",
                                backgroundColor: "white",
                                cursor: "pointer"
                            }}
                            onClick={ () => handleSelect(imageIndex) }
                        >
                            <ProductImage
                                image={ image }
                                size="75x75"
                                className="img-fluid rounded"
                                alt="Product"
                                style={{ objectFit: "contain" }}
                            />
                        </div>
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default React.memo(ProductImageCarousel);
